
export default {
  props: { item: { type: Object, required: true } },

  computed: {
    imgProps() {
      const img = this.$device.isDesktop
        ? this.item?.image
        : this.item?.image?.mobile ?? this.item?.image;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        sizes: "md:100vw lg:100vw xl:1280px",
        ...this.getImageDimensions(imgDimensions, 1280),
      };
    },
  },
};
