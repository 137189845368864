
import { path } from "ramda";

export default {
  props: { item: { type: Object, required: true } },

  computed: {
    imgSrc() {
      const name =
        this.item.data_type == "video" || this.item.data_type == "carousel"
          ? "cover_images"
          : "images";

      return path([[name], "medium_resolution"], this.item);
    },
    imgProps() {
      return {
        src: this.imgSrc,
        alt: path(["caption"], this.item),
        width: 296,
        height: 296,
        provider: "ipx",
      };
    },
  },
};
