
import { path } from "ramda";

export default {
  props: {
    primary: { type: Object, default: () => {} },
    item: { type: Object, required: true },
  },

  computed: {
    imgProps() {
      const img = path(["image"], this.item);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        width: path(["dimensions", "width"], img),
        height: path(["dimensions", "height"], img),
      };
    },
  },
};
