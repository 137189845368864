
import CenterOffer from "./CenterOffer";
import Plus from "./Plus";

export default {
  name: "OffersSection",

  components: { CenterOffer, Plus },

  props: {
    slice: { type: Object, required: true },
    context: { type: null, required: true },
  },

  computed: {
    hasPlusSection() {
      return this.slice?.primary?.info_title?.[0]?.text;
    },
  },
};
