
export default {
  props: {
    item: { type: Object, default: () => {} },
    label: { type: String, default: () => "" },
  },

  computed: {
    imgProps() {
      const img = this.item?.optional_icon;

      return {
        src: img?.url,
        alt: img?.alt,
        width: img?.dimensions?.width,
        height: img?.dimensions?.height,
      };
    },
  },
};
