
import CategoryItemCard from "./CategoryItemCard";
import FindYourDreamJobBanner from "./FindYourDreamJobBanner";

export default {
  components: { CategoryItemCard, FindYourDreamJobBanner },

  props: {
    primary: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    slice: { type: Object, required: true },
  },

  computed: {
    flexDirection() {
      const direction = this.$device.isMobile ? "flex-col-reverse" : "flex-col";

      return direction;
    },
  },
};
