
import { pathOr, filter, propEq, compose } from "ramda";
import { mapGetters } from "vuex";

const getColors = filter(propEq("name", "Colour"));

export default {
  props: {
    metaColors: { type: Object, default: () => {} }, // key value pair like {pink:["Colour Code","VariantID"]}
    handle: { type: String, default: "" },
    options: { type: Array, default: () => [] },
    initSelectedColor: { type: String, default: "" },
    showAll: { type: Boolean, default: false },
  },
  data() {
    return { selectedColor: this.initSelectedColor, MAX_COLORS: 3 };
  },
  computed: {
    ...mapGetters("products", ["getProductFetchingStatus"]),
    colorOptions() {
      return getColors(this.options);
    },
    hasColors() {
      return this.colorOptions.length != 0;
    },
    colors() {
      return compose(
        filter(x => this.metaColors[x]),
        pathOr([], [[0], "values"])
      )(this.colorOptions);
    },
    colorValues() {
      return this.colors.slice(0, this.MAX_COLORS);
    },
  },
  watch: {
    initSelectedColor: {
      handler(value) {
        this.selectedColor = value;
      },
    },
  },
};
