
import { colors } from "~/colors.config";

export default {
  props: {
    rating: { type: Number, default: () => {} },
    width: { type: Number, default: 24 },
    height: { type: Number, default: 24 },
    customerRatingViewBox: { type: String, default: "" },
  },
  data() {
    return {
      starPoints: this.$store.state.siteConfig.starPointsList,
      stars: [],
      emptyStar: 0,
      fullStar: 1,
      totalStars: 5,
      isIndicatorActive: false,
      style: {
        fullStarColor: colors.brown[100],
        emptyStarColor: colors.white,
      },
    };
  },
  computed: {
    starPointsToString() {
      return this.starPoints.join(",");
    },
    maxSize() {
      return this.starPoints.reduce(function (a, b) {
        return Math.max(a, b);
      });
    },
    viewBox() {
      if (this.customerRatingViewBox) return this.customerRatingViewBox;
      return `0 0 ${this.maxSize + 4} ${this.maxSize + 2}`;
    },
  },
  watch: {
    rating: {
      immediate: true,
      handler() {
        this.stars = [];
        this.initStars();
        this.setStars();
        this.calculatePoints();
      },
    },
  },
  methods: {
    initStars() {
      for (let i = 0; i < this.totalStars; i++) {
        this.stars.push({
          raw: this.emptyStar,
          percent: this.emptyStar + "%",
        });
      }
    },

    setStars() {
      let fullStarsCounter = Math.floor(this.rating);
      for (let i = 0; i < this.stars.length; i++) {
        if (fullStarsCounter !== 0) {
          this.stars[i].raw = this.fullStar;
          this.stars[i].percent = this.calcStarFullness(this.stars[i]);
          fullStarsCounter--;
        } else {
          let surplus = Math.round((this.rating % 1) * 10) / 10;
          // Support just one decimal
          let roundedOneDecimalPoint = Math.round(surplus * 10) / 10;
          this.stars[i].raw = roundedOneDecimalPoint;
          return (this.stars[i].percent = this.calcStarFullness(this.stars[i]));
        }
      }
    },
    calculatePoints() {
      this.starPoints = this.starPoints.map((point, i) => {
        const offset = i % 2 === 0 ? 4 * 1.5 : 0;
        return (20 / 30) * point + offset;
      });
    },
    calcStarFullness(starData) {
      let starFullnessPercent = starData.raw * 100 + "%";
      return starFullnessPercent;
    },
  },
};
