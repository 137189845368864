
import SwiperClass, { Navigation, Manipulation } from "swiper";
import { toLower, map, compose, flatten, path, sortBy } from "ramda";
import { createWidgetMixin } from "vue-instantsearch";
import { connectCurrentRefinements } from "instantsearch.js/es/connectors";
import { mapGetters } from "vuex";

import { multiSortMixin } from "~/mixins";

export default {
  mixins: [
    createWidgetMixin({ connector: connectCurrentRefinements }),
    multiSortMixin,
  ],

  props: { forMobile: { type: Boolean, default: false } },

  data: () => ({ isLastElement: false, isAway: false, swiper: null }),

  computed: {
    ...mapGetters(["shownFacets"]),
    widgetParams() {
      return {
        transformItems: this.transformItems,
        includedAttributes: this.shownFacets.map(f => f.name),
      };
    },
    swiperClass() {
      return this.forMobile ? "mobile-swiper" : "desktop-swiper";
    },
    swiperNextNavClass() {
      return `plp-refinements-next-${this.forMobile ? "mobile" : "desktop"}`;
    },
    swiperPrevNavClass() {
      return `plp-refinements-prev-${this.forMobile ? "mobile" : "desktop"}`;
    },
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        loop: false,
        loopFillGroupWithBlank: false,
        modules: [Navigation, Manipulation],
        navigation: {
          nextEl: `.${this.swiperNextNavClass}`,
          prevEl: `.${this.swiperPrevNavClass}`,
        },
        on: {
          reachEnd: () => {
            this.isLastElement = true;
          },
          fromEdge: swiper => {
            if (!swiper.isEnd) {
              this.isLastElement = false;
            }
          },
        },
      };
    },
    items() {
      const getRefinementItems = ({ refinements, refine }) => {
        return refinements.map(refinement => ({ ...refinement, refine }));
      };
      const getRefinements = compose(flatten, map(getRefinementItems));

      return this.state ? getRefinements(this.state.items) : [];
    },
  },

  watch: {
    items(value) {
      if (value.length !== 0) {
        this.$nextTick(() => {
          this.swiper?.update?.();
        });
      }
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      `.swiper.plp-selected-refinements.${this.swiperClass}`,
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },

  methods: {
    transformItems(items) {
      const newItems = items.map(item => {
        const refinements = sortBy(compose(toLower, path(["label"])))(
          item.refinements
        );
        return { ...item, refinements };
      });

      return this.multiSort(newItems, this.shownFacets, "label", "name");
    },
  },
};
