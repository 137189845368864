
export default {
  props: { items: { type: Array, default: () => [] } },

  computed: {
    videoFields() {
      return this.items.filter(item => item.video);
    },
  },
};
