
import ImageGrid from "./ImageGrid";
import ImageSwiper from "./ImageSwiper";

export default {
  components: { ImageGrid, ImageSwiper },

  props: {
    slice: { type: Object, required: true },
    flexBasis: { type: Object, required: true },
  },
};
