
import DesktopInstagramModal from "./DesktopInstagramModal";
import MobileInstagramModal from "./MobileInstagramModal";

export default {
  components: { DesktopInstagramModal, MobileInstagramModal },

  props: {
    gallery: { type: Array, default: () => {} },
    index: { type: Number, default: 0 },
    primary: { type: Object, default: () => {} },
  },

  data() {
    return { dataIndex: this.index, text: {} };
  },

  computed: {
    slideVisible() {
      return this.gallery[this.dataIndex];
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    viewOnInstagram() {
      window.open(this.slideVisible.url);
    },
    clickSwiper(index) {
      if (this.dataIndex == 0 && index == -1) {
        this.dataIndex = this.gallery.length - 1;
      } else {
        this.dataIndex = (this.dataIndex + index) % this.gallery.length;
      }
    },
    getImage(slideVisible) {
      if (slideVisible.data_type == "carousel") {
        return slideVisible.carousel_media.map(image => ({
          url: image.standard_resolution,
          altText: slideVisible.caption,
        }));
      } else {
        return [
          {
            url: slideVisible.images.high_resolution,
            altText: slideVisible.caption,
          },
        ];
      }
    },
  },
};
