
import VideoGrid from "./VideoGrid";
import VideoSwiper from "./VideoSwiper";

export default {
  components: { VideoGrid, VideoSwiper },

  props: {
    slice: { type: Object, required: true },
    flexBasis: { type: Object, required: true },
  },
};
