
export default {
  inject: { pageType: { default: "" } },

  props: {
    offer: { type: Object, required: true },
    hasMoreOffers: { type: Boolean, required: true },
  },

  computed: {
    offerClasses() {
      return {
        "has-offer-condition": this.offer.offer_condition,
        "has-more-offers": this.hasMoreOffers,
        "is-member": this.isMember,
      };
    },
    isMember() {
      return this.pageType === "Become_a_member";
    },
  },
};
