import { render, staticRenderFns } from "./index.vue?vue&type=template&id=91a6404a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=91a6404a&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91a6404a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsRichText: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/cms/CmsRichText.vue').default,AppButton: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppButton.vue').default,SvgIconErrorMessage: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconErrorMessage.vue').default,AppContainer: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppContainer.vue').default})
