
import { mapState } from "vuex";

export default {
  name: "MemberBanner",

  props: { slice: { type: Object, required: true } },

  computed: {
    ...mapState("app", ["topBannerHeight"]),
    image() {
      if (this.$device.isMobile && this.slice?.primary?.image?.mobile) {
        return this.slice?.primary?.image?.mobile;
      }

      if (this.$device.isTablet && this.slice?.primary?.image?.tablet) {
        return this.slice?.primary?.image?.tablet;
      }

      return this.slice?.primary?.image;
    },

    bgStyles() {
      const imgWidth = this.image?.dimensions?.width;
      const imgHeight = this.image?.dimensions?.height;
      const imgUrl = this.$img(this.image?.url, { width: imgWidth });

      return {
        backgroundImage: `url('${imgUrl}')`,
        minHeight: `${imgHeight}px`,
        backgroundPosition: "center top",
      };
    },
  },
  methods: {
    iconProps(bannerIcon) {
      const icon = this.$device.isMobileOrTablet
        ? bannerIcon?.tablet ?? bannerIcon
        : bannerIcon;
      const iconWidth = icon?.dimensions?.width;
      const iconSrc = icon?.url;
      return { width: iconWidth, src: iconSrc };
    },
    scrollToSection(sectionId) {
      const $section = document.getElementById(sectionId);
      const pdpStickyHeaderHeight = 80;
      if ($section) {
        window.scrollTo({
          top:
            $section.offsetTop - this.topBannerHeight - pdpStickyHeaderHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
