
import { AisStats } from "vue-instantsearch";
import { mapState } from "vuex";

export default {
  components: { AisStats },

  data: () => ({
    showViewAll: false,
    factedClicked: false,
    otherOverlayScreen: false,
  }),

  computed: {
    ...mapState("app", [
      "isFilterPanelOpen",
      "activeMenuIndex",
      "isMegaMenuOpen",
      "topBannerHeight",
      "isRefinementListVisible",
    ]),
  },

  watch: {
    isMegaMenuOpen(newVal) {
      this.watchMegaMenuProp(newVal);
    },
    activeMenuIndex(newVal) {
      if (this.$device.isDesktop) {
        this.watchMegaMenuProp(newVal > -1);
      }
    },
  },

  mounted() {
    this.$nuxt.$on("has-refinements", value => {
      this.showViewAll = value;
    });
  },

  methods: {
    watchMegaMenuProp(propVal) {
      if (propVal) {
        this.otherOverlayScreen = true;
      } else if (this.activeMenuIndex < 0) {
        setTimeout(() => {
          this.otherOverlayScreen = false;
        }, 1000);
      }
    },
    toggleFilterPanel() {
      if (this.isMobileOrTablet) {
        this.factedClicked = false;
        this.$store.dispatch("app/TOGGLE_FILTER_PANEL");
      }
    },
    getResultsText(nbHits) {
      return `view ${nbHits} ${nbHits == 1 ? "result" : "results"}`;
    },
  },
};
