
import ImageItem from "./ImageItem.vue";

export default {
  name: "ImageSectionV2",

  components: { ImageItem },

  props: { slice: { type: Object, required: true } },
  computed: {
    imageURL() {
      return this.slice?.primary?.image_link || null;
    },
  },
};
