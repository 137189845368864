import { gql } from "graphql-tag";

export const videoBanner = gql`
  fragment videoBanner on Campaign_sale1BodyVideo_banner {
    type
    primary {
      media_url
    }
  }
`;
