
import { mapGetters } from "vuex";

export default {
  computed: mapGetters(["shownFacets"]),

  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.$store.dispatch("app/setRefinementListVisiblity", isVisible);
      }
    },
  },
};
