
import MemberBenefitsCard from "./MemberBenefitsCard";

export default {
  name: "MemberBenefits",

  components: { MemberBenefitsCard },

  props: { slice: { type: Object, required: true } },
};
