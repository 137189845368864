
import IconGallery from "~/slices/IconGallery/index";

export default {
  name: "MattressBrandSection",

  components: { IconGallery },

  props: { slice: { type: Object, required: true } },

  computed: {
    isRight() {
      return this.slice.primary.media_alignment.toLowerCase() === "right";
    },
    theme() {
      return this.slice.label ? `prose-${this.slice.label}` : "";
    },
    imgProps() {
      const img = this.slice?.primary?.image;

      return {
        src: img?.url,
        alt: img?.alt,
        width: img?.dimensions?.width,
        height: img?.dimensions?.height,
      };
    },
    imgIconProps() {
      const img = this.slice?.primary?.optional_icon;

      return {
        src: img?.url,
        alt: img?.alt,
        width: img?.dimensions?.width,
        height: img?.dimensions?.height,
      };
    },
  },
};
