
import validation from "~/mixins/formValidation";

export default {
  name: "NewsSubscription",

  mixins: [validation],

  props: { slice: { type: Object, required: true } },

  data: () => ({
    email: "",
    hideForm: false,
    isSubmitting: false,
    errors: [],
  }),

  methods: {
    emailValidation() {
      this.errors = [];
      if (!this.email) {
        this.errors.push("This field is required.");
      }
      if (!this.validateEmail(this.email)) {
        this.errors.push("Please enter a valid email");
      }
    },
    async newsletterHandler() {
      this.emailValidation();
      if (this.errors?.length) {
        return;
      }

      this.isSubmitting = true;
      const url = `${this.$config.snoozeCrm}/subscriber`;
      try {
        const { data } = await this.$axios.post(url, `email=${this.email}`, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            apikey: this.$config.snoozeSystemApiKey,
          },
        });
        if (data?.customer_id) {
          this.hideForm = true;
        } else {
          this.errors.push("This email has been taken");
        }
      } catch (err) {
        err?.message && this.errors.push(err.message);
        this.$bugsnag.notify(err);
      }
      this.isSubmitting = false;
    },
  },
};
