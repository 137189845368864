
export default {
  name: "TextSection",

  props: { slice: { type: Object, required: true } },

  computed: {
    theme() {
      return this.slice.label
        ? `prose prose-${this.slice.label} lg:prose-${this.slice.label}-lg ${this.slice.label}`
        : "prose";
    },
  },
};
