
import { mapState, mapGetters } from "vuex";

import { validation } from "~/mixins";

export default {
  mixins: [validation],

  data: () => ({
    success: false,
    isSubmitting: false,
    first_name: null,
    last_name: null,
    email: null,
    postcode: null,
    mobile: null,
    errors: {
      first_name: [],
      last_name: [],
      email: [],
      postcode: [],
      mobile: [],
    },
    formErrors: [],
    wishlistProduct: null,
  }),

  computed: {
    ...mapState("wishlist", [
      "currentModalType",
      "modalOpenedFrom",
      "modalTypes",
      "customer",
    ]),
    ...mapGetters("wishlist", ["getItem"]),
  },

  mounted() {
    // We have to save the value in a local state, since on closing the modal
    // data gets deleted from state
    this.wishlistProduct = this.$store.state.wishlist.addToWishlistProduct;
  },

  async beforeDestroy() {
    if (!this.success) {
      // Do nothing
      return;
    }

    if (this.modalOpenedFrom === "HEADER") {
      this.$router.push("/wishlist");
      return;
    }

    if (!this.wishlistProduct) {
      return;
    }

    const { product, selectedVariant } = this.wishlistProduct;
    const wishlistItem = this.getItem(product.id, selectedVariant.id);
    if (wishlistItem) {
      // Do nothing, item is already in wishlist
      return;
    }

    if (
      this.modalOpenedFrom === "PDP" ||
      this.modalOpenedFrom === "BUNDLE_PRODUCT"
    ) {
      // Add if there was any product pending for wishlist addition
      await this.$store.dispatch("wishlist/addItem", this.wishlistProduct);
      return;
    }

    if (this.modalOpenedFrom === "PRODDUCT_TILE") {
      this.$store.dispatch("wishlist/openModal", {
        type: this.modalTypes.WISHLIST_ADD_MODAL,
        modalOpenedFrom: this.modalOpenedFrom,
        payload: this.wishlistProduct,
      });
    }
  },

  methods: {
    validateFirstName() {
      this.errors.first_name = [];
      if (!this.first_name) {
        this.errors.first_name.push("Please fill this field");
      }

      if (/[^A-Za-z]/.test(this.first_name)) {
        this.errors.first_name.push("Please enter valid first name");
      }
    },
    validateLastName() {
      this.errors.last_name = [];
      if (!this.last_name) {
        this.errors.last_name.push("Please fill this field");
      }

      if (/[^ A-Za-z]/.test(this.last_name)) {
        this.errors.last_name.push("Please enter valid last name");
      }
    },
    validateMyEmail() {
      this.errors.email = [];
      if (!this.email) {
        this.errors.email.push("Please fill this field");
      }

      if (!this.validateEmail(this.email)) {
        this.errors.email.push("Please include @ and . in email address");
      }
    },
    validatePostcode() {
      this.errors.postcode = [];
      if (!this.postcode) {
        this.errors.postcode.push("Please fill this field");
      }

      if (!/^\d{4}$/.test(this.postcode)) {
        this.errors.postcode.push("Please enter valid postcode");
      }
    },
    validateMobile() {
      this.errors.mobile = [];
      if (!this.mobile) {
        this.errors.mobile.push("Please fill this field");
      }

      if (!/^04/.test(this.mobile)) {
        this.errors.mobile.push("Mobile number must start with 04");
      }

      if (!/^\d{10}$/.test(this.mobile)) {
        this.errors.mobile.push("Please enter valid mobile number");
      }
    },
    validate() {
      this.validateFirstName();
      this.validateLastName();
      this.validateMyEmail();
      this.validatePostcode();
      this.validateMobile();
    },
    async signup() {
      const email = this.email;
      const body = {
        email,
        first_name: this.first_name,
        last_name: this.last_name,
        mobile: this.mobile,
        postcode: this.postcode,
        source: "online",
      };
      this.isSubmitting = true;

      try {
        await this.$store.dispatch("wishlist/signup", { body });
        await this.$store.dispatch("wishlist/createWishlist");

        this.success = true;
      } catch (err) {
        if (!err.response) {
          this.$bugsnag.notify(err, e => e.addMetadata("body", body));
          throw new Error("SIGNUP_ERR");
        }

        const errors = err.response.data?.errors ?? [];
        if (Array.isArray(errors)) {
          const errorMessage = errors[0]?.message;
          errorMessage && this.formErrors.push(errorMessage);
          throw new Error("SIGNUP_ERR");
        }

        const errorMessages = Object.entries(err.response.data?.errors);
        for (const [field, [message]] of errorMessages) {
          this.errors?.[field]?.push?.(message);
        }
        throw new Error("SIGNUP_ERR");
      } finally {
        this.isSubmitting = false;
      }
    },
    async handleSubmit() {
      this.validate();
      if (this.hasErros()) {
        return false;
      }

      this.formErrors = [];
      try {
        await this.signup();
        await this.$store.dispatch("bag/ADD_MEMBERSHIP_ATTRS", {
          email: this.customer.email,
          memberId: this.customer.customer_id,
        });

        this.$gtm.gtagEvent("sign_up", { email: this.customer.email });
      } catch (err) {
        // Signup error is already handled
        if (err.message !== "SIGNUP_ERR") {
          this.$bugsnag.notify(err);
        }
      }
    },
    handleClick(path) {
      this.$store.dispatch("wishlist/closeModal");
      this.$router.push(path);
    },
  },
};
