
export default {
  model: { prop: "selected", event: "change" },

  props: {
    defaultValue: { type: String, default: "" },
    selected: { type: String, default: "" },
  },

  data() {
    return { isChecked: this.defaultValue == this.selected };
  },

  watch: {
    selected: {
      immediate: true,
      handler(value) {
        this.isChecked = value == this.defaultValue;
      },
    },
  },

  methods: {
    changeHandler() {
      this.$emit("change", this.defaultValue);
    },
  },
};
