
import { path } from "ramda";

export default {
  props: { item: { type: Object, required: true } },

  computed: {
    imgProps() {
      const img = path(["image"], this.item);
      const imgDimensions = path(["dimensions"], img);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        ...this.getImageDimensions(imgDimensions, 330),
      };
    },
  },
};
