export default {
  data: () => ({ yotpoIsReady: false }),

  methods: {
    checkForYotpo() {
      setTimeout(() => {
        if (typeof window.yotpo !== "undefined") {
          if (window.yotpo.initialized === true) {
            this.yotpoIsReady = true;
            window.yotpo.refreshWidgets();
          }
        } else {
          console.log("Checking for Yotpo...");
        }

        if (this.yotpoIsReady === false) this.checkForYotpo();
      }, 500);
    },
  },
};
