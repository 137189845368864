
import SwiperClass, { Navigation, Pagination } from "swiper";

import GridSliderItem from "./GridSliderItem";

export default {
  components: { GridSliderItem },

  props: {
    fields: { type: Array, default: () => [] },
    index: { type: Number, required: true },
  },

  data() {
    return {
      swiperClass: `slider-alterntae-grid-${this.index}`,
      swiperNextNavClass: `slider-alternate-grid-swiper-next-${this.index}`,
      swiperPrevNavClass: `slider-alternate-grid-swiper-prev-${this.index}`,
      swiperPaginationClass: `slider-alternate-grid-swiper-pagination-${this.index}`,
    };
  },

  computed: {
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: 1,
        loop: true,
        nested: true,
        loopFillGroupWithBlank: false,
        modules: [Navigation, Pagination],
        pagination: {
          el: `.${this.swiperPaginationClass}`,
          clickable: true,
        },
        navigation: {
          nextEl: `.${this.swiperNextNavClass}`,
          prevEl: `.${this.swiperPrevNavClass}`,
        },
      };
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      `.swiper.${this.swiperClass}`,
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },
};
