import Vue from "vue";
import LazyHydrate from "vue-lazy-hydration";
import { getSliceComponentProps } from "@prismicio/vue/components";

export default function getCmsSlice(sliceName, SliceComponent) {
  return Vue.component(`hydrate-${sliceName}`, {
    props: getSliceComponentProps(["slice", "index", "slices", "context"]),

    render(h) {
      const { variation, ...rest } = this.slice ?? {};
      const updatedSlice = variation ? { ...rest, ...variation } : this.slice;
      const context = this.context ?? {};

      const slice = h(SliceComponent, {
        props: {
          context,
          slice: updatedSlice,
          index: this.index,
        },
        class: [updatedSlice.slice_type],
      });

      if (
        this.index == 0 ||
        this.slice.type === "list_of_products" ||
        this.context?.lazyHydrate === false
      ) {
        return slice;
      }

      const hydrateProps =
        this.slice.type == "product_catalogue_v2" || this.index <= 5
          ? { whenIdle: true }
          : { whenVisible: true };

      return h(LazyHydrate, { props: hydrateProps }, [slice]);
    },
  });
}
