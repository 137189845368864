export default {
  computed: {
    flexBasis() {
      let totalItems = this.slice?.fields
        ? this.slice.fields.length
        : this.slice.items.length;
      if (
        this.slice.label == "without-padding" ||
        this.slice.label == "horizontal-accordian"
      ) {
        return { "flex-basis": 100 / totalItems + "%" };
      } else {
        return {
          "flex-basis": (100 - (totalItems - 1) * 3) / totalItems + "%",
        };
      }
    },
  },
};
