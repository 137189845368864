
import MemberProgramIcon from "./MemberProgramIcon";
import AppShopButton from "~/components/common/AppShopButton";

export default {
  name: "MemberProgram",

  components: { MemberProgramIcon, AppShopButton },

  props: {
    slice: { type: Object, required: true },
    context: { type: Object, required: true },
  },

  computed: {
    exclusivePoints() {
      return this.slice.fields.filter(
        field => field.point_icon || field.exclusive_point
      );
    },
  },
};
