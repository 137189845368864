
export default {
  name: "StoreFinancialBlock",

  props: { slice: { type: Object, required: true } },

  computed: {
    logoProps() {
      return {
        src: this.slice.primary.ndis_logo?.url,
        alt: this.slice.primary.ndis_logo?.alt,
        width: this.slice.primary.ndis_logo?.dimensions.width,
        height: this.slice.primary.ndis_logo?.dimensions.height,
      };
    },
  },
};
