
import ProductList from "./ProductList";
import ProductCarousel from "./ProductCarousel";
import { gtmEventMixin } from "~/mixins";

export default {
  components: { ProductList, ProductCarousel },

  mixins: [gtmEventMixin],

  props: {
    slice: { type: Object, required: true },
    products: { type: Array, default: () => [] },
    isFetching: { type: Boolean, default: false },
    source: { type: String, default: "" },
  },

  computed: {
    productsProps() {
      return {
        products: this.products.map(product => ({
          ...product,
          source: this.source,
        })),
        sliceTitle: this.slice.primary?.title
          ? this.$prismic.asText(this.slice.primary.title)
          : "",
      };
    },
    listType() {
      return this.slice.primary?.list_type == false ? "gridList" : "carousel";
    },
  },

  watch: {
    products(value) {
      this.$store.dispatch("products/setRecommendedProduct", value?.[0]);
    },
  },

  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.gtmRelatedProductEvent(this.products);
      }
    },
  },
};
