
import ImgItem from "./ImgItem.vue";

export default {
  name: "ImageSection",
  components: { ImgItem },
  props: { slice: { type: Object, required: true } },

  computed: {
    imageURL() {
      return this.slice.primary?.image_link;
    },
  },
};
