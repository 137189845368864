
import MainOffer from "./MainOffer";
import { colors } from "~/colors.config";

export default {
  components: { MainOffer },

  inject: { pageType: { default: "" } },

  props: {
    field: { type: Object, default: () => {} },
    offers: { type: Array, default: () => [] },
    label: { type: String, default: "" },
  },

  computed: {
    hasMoreOffers() {
      return this.offers.length > 1;
    },
    hasOfferCondition() {
      return this.offers.length === 1 && this.offers[0].offer_condition;
    },
    hasOfferItems() {
      return this.offers.some(offer => Object.values(offer).some(Boolean));
    },
    bgColor() {
      return this.field.offer_banner_color || colors.campaign.sale;
    },
    bgClasses() {
      return this.field.has_gradient
        ? `${this.field.gradient_direction} ${this.field.start_color} ${this.field.end_color}`
        : "bg-color";
    },
    plusOfferPrefix() {
      return this.$prismic.asText(this.field.plus_offer_prefix);
    },
    plusOffer() {
      return this.$prismic.asText(this.field.plus_offer);
    },
    isMember() {
      return this.pageType === "Become_a_member";
    },
    hasOutlineWidth() {
      return this.field?.offer_title?.some(f =>
        f.spans.find(span => span.data.label === "text-outline-width")
      );
    },
  },
};
