
import ShopifyProductTile from "~/components/common/AppShopifyProductTile";

export default {
  components: { ShopifyProductTile },

  props: {
    products: { type: Array, required: true },
    sliceTitle: { type: String, required: true },
  },
};
