
import { mapState } from "vuex";
import { validation } from "~/mixins";

export default {
  name: "FeedbackForm",

  mixins: [validation],

  data: () => ({
    firstName: null,
    lastName: null,
    email: null,
    message: null,
    submitting: false,
    formErros: [],
    errors: {
      firstName: [],
      lastName: [],
      email: [],
      message: [],
    },
  }),

  computed: {
    ...mapState(["siteConfig"]),
  },

  methods: {
    validateFirstName() {
      this.errors.firstName = [];
      if (!this.firstName) {
        this.errors.firstName.push("Please fill this field");
      }

      if (/[^ A-Za-z0-9_]/.test(this.firstName)) {
        this.errors.firstName.push("Please enter valid firstName");
      }
    },
    validateLastName() {
      this.errors.lastName = [];
      if (!this.lastName) {
        this.errors.lastName.push("Please fill this field");
      }

      if (/[^ A-Za-z0-9_]/.test(this.lastName)) {
        this.errors.lastName.push("Please enter valid last name");
      }
    },
    validateMyEmail() {
      this.errors.email = [];
      if (!this.email) {
        this.errors.email.push("Please fill this field");
      }

      if (!this.validateEmail(this.email)) {
        this.errors.email.push("Please include @ and . in email address");
      }
    },
    validateMessage() {
      this.errors.message = [];
      if (!this.message) {
        this.errors.message.push("Please fill this field");
      }
    },
    validate() {
      this.validateFirstName();
      this.validateLastName();
      this.validateMyEmail();
      this.validateMessage();
    },
    async onSubmit() {
      this.validate();

      if (this.hasErros()) {
        return false;
      }

      this.submitting = true;
      this.formErros = [];

      await this.sendMail();
      if (this.formErros.length !== 0) {
        return;
      }

      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.message = null;
      this.$store.dispatch(
        "pages/setNotificationContent",
        "Feedback Submitted"
      );
    },
    async sendMail() {
      const body = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        message: this.message,
        formName: "feedback",
      };

      try {
        await this.$axios.post("api/send-mail", body);
      } catch (err) {
        this.$bugsnag.notify(err, e => e.addMetadata("body", body));
        this.formErros.push("Some error occured");
      }
    },
  },
  // async createZendeskRequest() {
  //   const { zendeskFirstNameId, zendeskLastNameId } = this.siteConfig.contactUs;
  //   const comment = {
  //     body: `Message:\n${this.message}\n`,
  //   };
  //   const body = {
  //     request: {
  //       requester: {
  //         name: `${this.firstName} ${this.lastName}`,
  //         email: this.email,
  //       },
  //       subject: this.$config.zendeskFeedbackFormSubject,
  //       comment,
  //       custom_fields: [
  //         { id: zendeskFirstNameId, value: this.firstName },
  //         { id: zendeskLastNameId, value: this.lastName },
  //       ],
  //     },
  //   };
  //   try {
  //     await this.$axios.post("api/zendesk-request", body);
  //   } catch (err) {
  //     this.$bugsnag.notify(err, e => e.addMetadata("body", body));
  //     this.formErros.push("Some error occured");
  //   }
  // },
};
