
import ImageBannerForSubtitle from "./ImageBannerForSubtitle";

export default {
  components: { ImageBannerForSubtitle },

  props: { slice: { type: Object, required: true } },

  computed: {
    image() {
      if (this.$device.isMobile && this.slice?.primary?.image?.mobile) {
        return this.slice?.primary?.image?.mobile;
      }

      return this.slice?.primary?.image?.desktop;
    },
    bgStyles() {
      const imgWidth = this.image?.dimensions?.width;
      const imgHeight = this.image?.dimensions?.height;
      const imgUrl = this.$img(this.image?.url, { width: imgWidth });

      return {
        backgroundImage: `url('${imgUrl}')`,
        minHeight: `${imgHeight}px`,
        backgroundPosition: "center top",
      };
    },
  },
  methods: {
    iconProps(bannerIcon) {
      const icon = this.$device.isMobileOrTablet
        ? bannerIcon?.tablet ?? bannerIcon
        : bannerIcon;
      const iconWidth = icon?.dimensions?.width;
      const iconSrc = icon?.url;

      return { width: iconWidth, src: iconSrc };
    },
  },
};
