
import { gql } from "graphql-tag";

import ClpFacetItem from "./ClpFacetItem";
import { documentLink } from "~/app/prismic/prismic-fragments";
import { normalizeEdges } from "~/utils";

const COLLECTION_FACET_QUERY = gql`
  query allCollection_facets($uid: String!) {
    allCollection_facets(where: { collection_uid: $uid }) {
      edges {
        node {
          ... on Collection_facet {
            facet_title
            facet_values {
              title
              icon
              plp_page_link {
                ...documentLink
              }
            }
          }
        }
      }
    }
  }
  ${documentLink}
`;

export default {
  name: "ListOfFacets",

  components: { ClpFacetItem },

  inject: {
    title: { default: "" },
  },

  props: { slice: { type: Object, required: true } },

  data: () => ({ facetFields: [] }),

  async fetch() {
    const allFacets = await this.$getPrismicData({
      query: COLLECTION_FACET_QUERY,
      variables: { uid: this.$route.params.handle },
    });
    this.facetFields = normalizeEdges(allFacets?.allCollection_facets);

    const attachedFacets = this.slice.fields.map(
      field => field?.shop_by_facets?.facet_title?.[0]?.text ?? ""
    );
    const getMatchedIndex = node =>
      attachedFacets.indexOf(node?.facet_title?.[0]?.text ?? "");

    this.facetFields = this.facetFields
      .filter(field => {
        const fieldName = field?.facet_title?.[0]?.text ?? "";
        return attachedFacets.includes(fieldName);
      })
      .sort((a, b) => {
        return getMatchedIndex(a) - getMatchedIndex(b);
      });
  },

  computed: {
    hasTitle() {
      return this.slice.primary?.title1?.[0]?.text;
    },
    viewAllRoute() {
      return `${this.$route.path}/products?sortBy=${this.$config.algoliaDefaultSortProductsIndex}`;
    },
  },
};
