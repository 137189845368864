
export default {
  name: "CallToActionBanner",

  props: { slice: { type: Object, required: true } },

  data: () => ({ bannerBgHeight: "256" }),

  computed: {
    isLeftAligned() {
      return this.slice.label === "left_aligned";
    },
    bannerImage() {
      const optionalImg = this.slice?.primary?.optional_image;
      const optionalImgMobile = this.slice?.primary?.optional_image?.mobile;

      if (!this.isMobile) {
        return optionalImg;
      }

      return optionalImgMobile || optionalImg;
    },
    bgStyles() {
      const imgSrc = this.bannerImage?.url;
      const imgDimensions = this.bannerImage?.dimensions;

      const { width, height } = this.getImageDimensions(
        imgDimensions,
        null,
        this.bannerBgHeight
      );

      return {
        backgroundImage: imgSrc
          ? `url('${this.$img(imgSrc, { width, height })}')`
          : "",
        minHeight: `${this.bannerBgHeight}px`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      };
    },
  },
};
