import { render, staticRenderFns } from "./DesktopInstagramModal.vue?vue&type=template&id=68435e11&scoped=true&"
import script from "./DesktopInstagramModal.vue?vue&type=script&lang=js&"
export * from "./DesktopInstagramModal.vue?vue&type=script&lang=js&"
import style0 from "./DesktopInstagramModal.vue?vue&type=style&index=0&id=68435e11&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68435e11",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIconSnoozeLogo: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconSnoozeLogo.vue').default,AppExternalLink: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppExternalLink.vue').default,CmsRichText: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/cms/CmsRichText.vue').default,SwiperNav: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/swiper-nav/SwiperNav.vue').default})
