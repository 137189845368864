
export default {
  props: { slice: { type: Object, required: true } },

  computed: {
    title() {
      let text = "";
      if (
        this.slice.primary?.title?.length > 0 &&
        this.slice.primary?.title[0]?.text
      ) {
        text = this.slice.primary?.title[0]?.text;
      }

      return text;
    },
    image() {
      if (this.$device.isMobile && this.slice?.primary?.image?.mobile) {
        return this.slice?.primary?.image?.mobile;
      }

      return this.slice?.primary?.image?.desktop;
    },
    bgStyles() {
      const imgWidth = this.image?.dimensions?.width;
      const imgHeight = this.image?.dimensions?.height;
      const imgUrl = this.$img(this.image?.url, { width: imgWidth });

      return {
        backgroundImage: `url('${imgUrl}')`,
        minHeight: `${imgHeight}px`,
        backgroundPosition: "center top",
      };
    },
  },
};
