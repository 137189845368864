
import OfferSection from "./OfferSection";
import AppShopButton from "~/components/common/AppShopButton";
// import { slice, context } from "./custom-mock.json";
import MemberProgramIcon from "~/slices/MemberProgram/MemberProgramIcon";

export default {
  name: "MemberProgramV2",

  components: { OfferSection, MemberProgramIcon, AppShopButton },

  props: {
    slice: { type: Object, required: true },
    context: { type: null, required: true },
  },

  // data: () => ({ slice, context }),

  computed: {
    points() {
      return this.slice.items.filter(
        item => item.point_icon || item.exclusive_point
      );
    },
    // Needed for slice simulator
    bgStyle() {
      const mockImageUrl = this.context?.mock_bg_image?.url ?? null;
      return mockImageUrl
        ? { backgroundImage: `url("${this.$img(mockImageUrl)}")` }
        : null;
    },
  },
};
