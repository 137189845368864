
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    item: { type: Object, default: () => {} },
    label: { type: String, default: () => "" },
  },

  data: () => ({ selectedOption: null, errors: [] }),

  computed: {
    ...mapState("wishlist", ["modalTypes", "customer", "fillColor"]),
    ...mapGetters("wishlist", ["getItem"]),
    wishlistItem() {
      const { productId, variantId } = this.selectedOption ?? {};
      return this.getItem(productId, variantId);
    },
    imgProps() {
      const img = this.item.image;

      return {
        src: img?.url,
        alt: img?.alt,
        width: img?.dimensions?.width,
        height: img?.dimensions?.height,
      };
    },
    optionsSelector() {
      const textValue = this.item.options_selector?.[0]?.text;
      return textValue ? JSON.parse(textValue) : null;
    },
    buttonLink() {
      const queryParams = this.selectedOption
        ? `?variant=${this.selectedOption.variantId}`
        : "";

      return this.$prismic.asLink(this.item.button_link) + queryParams;
    },
  },

  created() {
    this.selectedOption = this.optionsSelector?.[0];
  },

  methods: {
    getPrice(price) {
      return price.match(/(?<value>\d.*)/).groups.value?.replace(",", "");
    },
    handleHeartIconClick() {
      const { productId, variantId, optionValue } = this.selectedOption ?? {};

      if (!productId || !variantId) {
        this.errors.push(
          `${productId ? "Variant id" : "Product id"} id is required`
        );
        return;
      }

      const addPayload = {
        selectedVariant: {
          id: variantId,
          sku: variantId,
          title: optionValue,
          price: this.getPrice(this.selectedOption.price),
          compareAtPrice: this.getPrice(this.selectedOption.compareAtPrice),
        },
        product: {
          id: productId,
          title: this.$prismic.asText(this.item.subtitle),
        },
      };

      if (!this.customer?.email) {
        this.$store.dispatch("wishlist/openModal", {
          type: this.modalTypes.LOGIN_MODAL,
          modalOpenedFrom: "BUNDLE_PRODUCT",
          payload: addPayload,
        });
        return;
      }

      if (this.wishlistItem) {
        this.$store.dispatch("wishlist/removeItem", { productId, variantId });
        return;
      }

      this.$store.dispatch("wishlist/addItem", addPayload);
    },
  },
};
