
export default {
  props: { item: { type: Object, required: true } },

  computed: {
    imgProps() {
      const img = this.$device.isMobile
        ? this.item?.image?.mobile
        : this.item?.image?.desktop;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        height: imgDimensions?.height,
        fit: "contain",
        sizes: "",
      };
    },
    bgStyles() {
      const imgHeight = this.imgProps?.height;

      return {
        maxHeight: `${imgHeight}px`,
      };
    },
  },
};
