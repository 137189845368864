
import { gql } from "graphql-tag";

import MobileView from "./MobileView";
import DesktopView from "./DesktopView";
import {
  BlogDetail,
  getListOfArticles,
} from "~/slices/ListOfArticles/fragment";
import { normalizeEdges } from "~/utils";

export default {
  name: "ListOfArticles",

  components: { MobileView, DesktopView },

  inject: {
    uid: { default: "" },
    blogTags: { default: () => [] },
    pageType: { default: "" },
    collectionType: { default: "" },
  },

  props: {
    slice: { type: Object, required: true },
    index: { type: Number, required: true },
  },

  data: () => ({ blogs: [] }),

  async fetch() {
    this.blogs =
      this.pageType === "Product_detail_page"
        ? await this.getBlogsByTag()
        : await this.getBlogs();
  },

  fetchOnServer: false,

  computed: {
    items() {
      return this.blogs.filter(
        blog =>
          blog?.title?.[0]?.text &&
          blog?.description?.[0] &&
          blog?.feature_image
      );
    },
    query() {
      const pageType = this.pageType?.toLowerCase();

      return gql`
        query ${pageType}($uid: String!, $lang: String!, $lazy: Boolean = true) {
          ${this.pageType}:${pageType}(uid: $uid, lang: $lang) {
            slices: body {
              ... ${this.pageType}_list_of_articles
              }
            }
          }
          ${getListOfArticles(this.pageType)}
      `;
    },
    queryByTag() {
      return gql`
        query allBlogs($tags: [String!]) {
          allBlogs(tags_in: $tags, first: 4) {
            edges {
              node {
                ...BlogDetail
              }
            }
          }
        }
        ${BlogDetail}
      `;
    },
  },

  methods: {
    async getBlogs() {
      const data = await this.$getPrismicData({
        query: this.query,
        variables: { uid: this.uid },
      });

      return data[this.pageType].slices?.[this.index]?.fields
        ?.map(field => field?.blog_link)
        .filter(Boolean);
    },
    async getBlogsByTag() {
      const { allBlogs } = await this.$getPrismicData({
        query: this.queryByTag,
        variables: { tags: this.blogTags },
      });

      return normalizeEdges(allBlogs);
    },
  },
};
