
export default {
  props: { subtitleImage: { type: Object, required: true } },

  computed: {
    image() {
      const bannerImg = this.subtitleImage;
      const img = this.$device.isMobile
        ? bannerImg?.mobile
        : bannerImg?.desktop;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        height: imgDimensions?.height,
        fit: "contain",
        sizes: "",
      };
    },
  },
};
