
import SwiperClass from "swiper";

import RelatedArticle from "./RelatedArticle";

export default {
  components: { RelatedArticle },

  props: { fields: { type: Array, default: () => {} } },

  data: () => ({ swiper: null }),

  computed: {
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        loop: false,
        loopFillGroupWithBlank: false,
      };
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      ".swiper.m-list-of-artilces",
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },
};
