
export default {
  props: { point: { type: Object, required: true } },

  computed: {
    imgProps() {
      const img = this.point?.point_icon;

      return {
        src: img?.url,
        width: img?.dimensions?.width,
        height: img?.dimensions?.height,
      };
    },
  },
};
