
export default {
  name: "TextBlock",

  inject: { pageType: { default: "" } },

  props: {
    slice: { type: Object, required: true },
    context: { type: Object, default: () => {} },
  },

  computed: {
    theme() {
      return this.slice.label
        ? `prose prose-${this.slice.label} lg:prose-${this.slice.label}-lg`
        : "prose prose-pages";
    },
    isPlp() {
      return this.pageType === "Product_list_page";
    },
    isClp() {
      return this.pageType === "Collection_page";
    },
    isL2Plp() {
      return this.pageType === "L2_product_list_page";
    },
  },
};
