
import { pathOr, filter } from "ramda";

import IconGalleryItem from "./IconGalleryItem";
import calculateFlexBasis from "~/mixins/calculateFlexBasisMixin";

const filterItems = filter(pathOr(false, ["icon"]));

export default {
  name: "IconGallery",

  components: { IconGalleryItem },

  mixins: [calculateFlexBasis],

  props: { slice: { type: Object, required: true } },

  computed: {
    items() {
      return filterItems(this.slice.fields);
    },
  },
};
