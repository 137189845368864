
import SwiperClass, { Pagination } from "swiper";

export default {
  props: { siteReview: { type: Array, default: () => [] } },

  data: () => ({ swiper: null }),

  computed: {
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        loop: false,
        loopFillGroupWithBlank: false,
        modules: [Pagination],
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      };
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      ".swiper.mobile-reviewes",
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },
};
