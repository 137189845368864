
import InternalVideoGridItem from "./InternalVideoGridItem";

export default {
  components: { InternalVideoGridItem },

  props: {
    items: { type: Array, default: () => [] },
    flexBasis: { type: Object, required: true },
  },
};
