import { gql } from "graphql-tag";

export const textOverImage = gql`
  fragment textOverImage on Campaign_sale1BodyText_over_image {
    type
    primary {
      description
      padding_top
    }
  }
`;
