
import { mapState } from "vuex";
import SwiperClass, { Navigation, Pagination, Autoplay } from "swiper";

export default {
  name: "HeroCarousel",

  props: { slice: { type: Object, required: true } },

  data: () => ({ toStop: false, hideArrows: true, swiper: null }),

  computed: {
    ...mapState(["siteConfig"]),
    swiperOptions() {
      return {
        loop: true,
        spaceBetween: 5,
        centeredSlides: true,
        modules: [Navigation, Autoplay, Pagination],
        autoplay: {
          delay: this.slice.primary.time_interval,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".hero-carousel-pagination",
          clickable: true,
          renderBullet: (index, className) =>
            `<span class="${className}">&nbsp;&nbsp;</span>`,
        },
        navigation: {
          nextEl: ".hero-carousel-nav-next",
          prevEl: ".hero-carousel-nav-prev",
        },
      };
    },
    displayArrows() {
      return this.$device.isDesktop && this.slice.fields.length > 1;
    },
    carouselSlides() {
      return this.slice.fields
        .filter(field => Boolean(field.campaign_sale_link1))
        .slice(0, this.siteConfig.home.carouselLimit)
        .map(x => x?.campaign_sale_link1);
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      ".swiper.hero-carousel-swiper",
      this.swiperOptions
    );
    this.swiper.on("click", this.handleVideoBannerClick);
  },

  destroyed() {
    this.swiper?.autoplay?.stop();
    this.swiper?.destroy?.();
  },

  methods: {
    onNavigationClicked() {
      console.log("Navigation clicked!!!");
      this.toStop = !this.toStop;
      if (this.toStop && this.swiper?.autoplay.running) {
        this.swiper?.autoplay.pause();
      } else {
        this.swiper?.autoplay.start();
      }
    },
    handleVideoBannerClick({ slides, activeIndex, autoplay }) {
      const activeSlide = slides[activeIndex];
      if (
        activeSlide &&
        activeSlide.querySelector(".swiper-video-banner") == null
      ) {
        return;
      }

      console.log("------VideoBanner Clicked------");
      autoplay.stop();
    },
  },
};
