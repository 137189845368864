
import { path } from "ramda";

import GridSlider from "./GridSlider";

export default {
  name: "SliderAlternateGrid",

  components: { GridSlider },

  props: {
    slice: { type: Object, required: true },
    index: { type: Number, required: true },
  },

  computed: {
    isRight() {
      return this.slice.primary.media_alignment.toLowerCase() === "right";
    },
    imgProps() {
      const img = path(["optional_icon"], this.slice.primary);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        width: path(["dimensions", "width"], img),
        height: path(["dimensions", "height"], img),
      };
    },
  },
};
