
const MAX_CHARS = 300;

export default {
  name: "HeroBanner",

  props: { slice: { type: Object, required: true } },

  data: () => ({ isReadMore: true, bannerBgHeight: 480 }),

  computed: {
    paragraphText() {
      return this.$prismic.asText(this.slice.primary?.paragraph) ?? "";
    },
    showReadMore() {
      return this.paragraphText.length > MAX_CHARS;
    },
    bannerBgImgSrc() {
      return this.slice.primary?.banner_bg_image?.url;
    },
    bannerBgImgWidth() {
      return this.slice.primary?.banner_bg_image?.dimensions?.width;
    },
    bgStyle() {
      if (!this.bannerBgImgSrc) {
        return null;
      }

      const imgUrl = this.$img(this.bannerBgImgSrc, {
        height: this.bannerBgHeight,
        width: this.imageWidth,
      });

      return { backgroundImage: `url(${imgUrl})` };
    },
    maxChars() {
      return this.showReadMore && this.isReadMore ? MAX_CHARS : 0;
    },
  },
};
