
import { pathOr, filter, allPass } from "ramda";

import MobileTopCategory from "./MobileTopCategory";
import DesktopTopCategory from "./DesktopTopCategory";

const pathToCollection = ["link", "body", [0], "variation", "primary"];
const filterItems = filter(
  allPass([
    pathOr(false, [...pathToCollection, "title", [0], "text"]),
    pathOr(false, [...pathToCollection, "paragraph", [0], "text"]),
    pathOr(false, [...pathToCollection, "banner_bg_image"]),
  ])
);

export default {
  name: "TopCategory",

  components: { MobileTopCategory, DesktopTopCategory },

  props: { slice: { type: Object, required: true } },

  computed: {
    items() {
      return filterItems(this.slice.fields);
    },
  },
};
