
export default {
  props: { slice: { type: Object, required: true } },

  computed: {
    image() {
      if (this.$device.isMobile && this.slice?.primary?.image?.mobile) {
        return this.slice?.primary?.image?.mobile;
      }

      return this.slice?.primary?.image?.desktop;
    },
    titleimage() {
      const bannerImg = this.slice.primary?.titleimage;
      const img = this.$device.isMobile
        ? bannerImg?.mobile
        : this.$device.isTablet
        ? bannerImg?.tablet
        : bannerImg?.desktop;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
    bgStyles() {
      const imgWidth = this.image?.dimensions?.width;
      const sectionHeight = this.$device.isMobile
        ? this.slice?.primary?.section_height_mobile
        : this.slice?.primary?.section_height_desktop;
      const imgHeight = sectionHeight || this.image?.dimensions?.height;
      const imgUrl = this.image?.url
        ? this.$img(this.image?.url, { width: imgWidth })
        : "";
      const justifyContent = this.slice?.primary?.justify_content;
      const noPadding = !!justifyContent;

      return {
        backgroundImage: `url('${imgUrl}')`,
        minHeight: `${imgHeight}px`,
        backgroundPosition: "center top",
        justifyContent,
        noPadding,
      };
    },
  },
  methods: {
    iconProps(bannerIcon) {
      const icon = this.$device.isMobileOrTablet
        ? bannerIcon?.tablet ?? bannerIcon
        : bannerIcon;
      const iconWidth = icon?.dimensions?.width;
      const iconSrc = icon?.url;

      return { width: iconWidth, src: iconSrc };
    },
  },
};
