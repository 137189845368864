
export default {
  props: { item: { type: Object, default: () => {} } },

  computed: {
    primary() {
      return this.item?.body?.[0]?.variation?.primary;
    },
    imgProps() {
      const img = this.primary?.banner_bg_image?.HomePage;

      return {
        src: img?.url,
        alt: img?.alt,
        width: img?.dimensions?.width,
        height: img?.dimensions?.width,
      };
    },
  },
};
