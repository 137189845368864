
import { mapGetters, mapActions } from "vuex";

import ProductsFetch from "./ProductsFetch";

export default {
  components: { ProductsFetch },

  props: {
    slice: { type: Object, required: true },
    context: { type: Object, default: () => {} },
  },

  data: () => ({ products: [] }),

  async fetch() {
    const { tag, max_products_limit: limits } = this.slice.primary;

    const { sku, cartSkus } = this.context ?? {};
    const productSkuQuery = sku ? `&product=${sku}` : "";
    const cartSkusQuery = cartSkus ? `&cart=${cartSkus}` : "";

    const lastViewedProducts = this.$searchspring.getSsViewedProducts();
    const lastViewedQuery = lastViewedProducts
      ? `&lastViewed=${lastViewedProducts}`
      : "";

    const url = `${this.$config.searchspringUrl}/recommend?tags=${tag}&limits=${limits}${productSkuQuery}${cartSkusQuery}${lastViewedQuery}`;

    await this.$store.dispatch("recommendations/fetchProducts", { tag, url });

    this.products = this.normalizeProducts(this.getProducts(tag));

    await this.trackBeacon({ type: "render", tag: this.slice.primary.tag });
  },

  fetchOnServer: false,

  computed: {
    ...mapGetters("bag", ["lines"]),
    ...mapGetters("recommendations", ["getProducts", "getVisibleProductIds"]),
    visibleProductIds() {
      return this.getVisibleProductIds(this.slice.primary.tag);
    },
  },

  mounted() {
    const unwatch = this.$watch("visibleProductIds", productIds => {
      if (productIds.length > 0) {
        // Add setTimeout so that all products are in visible list for current impression
        setTimeout(() => {
          this.trackBeacon({ type: "impression", tag: this.slice.primary.tag });
        }, 250);
        unwatch();
      }
    });
  },

  methods: {
    ...mapActions("recommendations", ["trackBeacon"]),
    getMetafields(metafields) {
      return metafields.reduce((acc, { key, value, value_type: type }) => {
        return {
          ...acc,
          [key]: type.includes("list.") ? JSON.parse(value ?? null) : value,
        };
      }, {});
    },
    normalizeProducts(recommendedProducts) {
      return recommendedProducts.map(({ mappings, attributes }) => {
        const options = JSON.parse(attributes.options ?? null) ?? [];

        return {
          recId: mappings.core.uid,
          title: mappings.core.name,
          brandList: mappings.core.brand,
          featuredImage: {
            id: mappings.core.thumbnailImageUrl,
            src: mappings.core.thumbnailImageUrl,
            w_288_h_288: mappings.core.thumbnailImageUrl,
          },
          handle: attributes.handle,
          id: attributes.variant_product_id,
          collections: attributes.collection_name, // used in gtm GA3
          options,
          metafields: this.getMetafields(JSON.parse(attributes.metafields)),
          variants: JSON.parse(attributes.variants).map(variant => ({
            price: variant.price,
            compareAtPrice: variant.compare_at_price,
            id: variant.id,
            productId: variant.product_id,
            title: variant.title,
            metafields: this.getMetafields(variant?.metafields ?? {}),
            selectedOptions: options.reduce((acc, { name, position }) => {
              return {
                ...acc,
                [name]: { name, value: variant[`option${position}`] },
              };
            }, {}),
          })),
          tags: attributes.tags,
        };
      });
    },
  },
};
