
import { path } from "ramda";

export default {
  props: { item: { type: Object, required: true } },

  computed: {
    imgProps() {
      const img = path(["image"], this.item);
      const imgDimensions = path(["dimensions"], img);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        sizes: "md:100vw lg:100vw xl:1280px",
        ...this.getImageDimensions(imgDimensions, 1280),
      };
    },
  },
};
