
export default {
  props: {
    handle: { type: String, default: "" },
    selectedVariantId: { type: [String, Number], default: "" },
    reviewsAverage: { type: [String, Number], default: "0" },
    reviewsCount: { type: [String, Number], default: "0" },
  },
  computed: {
    rating() {
      return Number(this.reviewsAverage);
    },
  },
  methods: {
    onClick() {
      this.$router.push(
        `/products/${this.handle}?variant=${this.selectedVariantId}`
      );
    },
  },
};
