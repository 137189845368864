import { gql } from "graphql-tag";

import { documentLink } from "~/app/prismic/prismic-fragments";

export const offersSection = gql`
  fragment offersSection on Campaign_sale1BodyOffers_section {
    type
    primary {
      title1
      sale_type
      sale_banner_color
      has_gradient
      start_color
      end_color
      gradient_direction
      info_title
      info_instruction1
      info_instruction2
      info_instruction3
      info_instruction4
      plus_background_color
      plus_has_gradient
      plus_start_color
      plus_end_color
      plus_gradient_direction
    }
    fields {
      sale_percentage_text
      sale_percentage
      sale_items
    }
  }
  ${documentLink}
`;
