
import VariationImage from "./VariationImage";
import VariationVideo from "./VariationVideo";
import VariationInternalVideo from "./VariationInternalVideo";
import calculateFlexBasis from "~/mixins/calculateFlexBasisMixin";

export default {
  name: "MediaGridV2",

  components: {
    VariationImage,
    VariationVideo,
    VariationInternalVideo,
  },

  mixins: [calculateFlexBasis],

  props: {
    slice: { type: Object, required: true },
    context: { type: Object, default: () => {} },
  },
};
