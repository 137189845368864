
import { AisInstantSearchSsr, AisConfigure } from "vue-instantsearch";

import { createSearchSSRMixin, facetsMixin } from "~/mixins";

export default {
  name: "ListOfProducts",

  components: { AisInstantSearchSsr, AisConfigure },

  mixins: [facetsMixin],

  props: { context: { type: Object, default: null } },

  data() {
    const {
      algoliaAppId,
      algoliaKey,
      algoliaProductsIndex: indexName,
      algoliaDefaultSortProductsIndex,
    } = this.$store.state.siteConfig;

    const mixin = createSearchSSRMixin({
      algoliaAppId,
      algoliaKey,
      indexName,
      router: this.$router,
      facets: ["refinementList", "sortBy", "range", "page"],
      algoliaDefaultSortProductsIndex,
      bugsnag: this.$bugsnag,
    });

    return { ...mixin.data() };
  },

  computed: {
    handle() {
      return this.$route.params.handle;
    },
    viewAll() {
      return this.$route.query.view_all == 1;
    },
    hitsPerPage() {
      return this.viewAll
        ? 1000
        : this.$device.isMobile
        ? this.context?.display_banners
          ? 17
          : 18
        : this.context?.display_banners
        ? 11
        : 12;
    },
  },
};
