
import { path } from "ramda";

export default {
  props: {
    open: { type: Boolean, default: false },
    item: { type: Object, default: () => {} },
    label: { type: String, default: () => "" },
    horizontalAccordian: { type: Boolean, default: false },
  },

  computed: {
    description() {
      return this.item.accordDesc || this.item.item_description;
    },
    imgProps() {
      const img = path(["optional_icon"], this.item);
      const imgWidth = path(["dimensions", "width"], img);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        ...this.getImageDimensions({ width: imgWidth, height: imgWidth }, 100),
      };
    },
  },
};
