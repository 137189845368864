
export default {
  props: {
    bannerConfig: { type: Object, default: null },
    bannerPosition: { type: Number, default: -1 },
  },

  computed: {
    bannerImgProps() {
      const bannerImg =
        this.bannerPosition % 6 === 0
          ? this.bannerConfig?.image_1
          : this.bannerConfig?.image_2;
      const img = this.$device.isMobile ? bannerImg?.mobile : bannerImg;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
    bannerLink() {
      const link =
        this.bannerPosition % 6 === 0
          ? this.bannerConfig?.image_1_link
          : this.bannerConfig?.image_2_link;

      return link;
    },
  },
};
