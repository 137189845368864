import { render, staticRenderFns } from "./PlpProductHits.vue?vue&type=template&id=679bf5e9&"
import script from "./PlpProductHits.vue?vue&type=script&lang=js&"
export * from "./PlpProductHits.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButton: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppButton.vue').default,ProductTileImageBanner: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/product-tile/ProductTileImageBanner.vue').default,ProductTileRating: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/product-tile/ProductTileRating.vue').default,ProductTile: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/product-tile/ProductTile.vue').default})
