
import { path } from "ramda";

export default {
  name: "HeroBanner",

  props: { slice: { type: Object, required: true } },

  data: () => ({ isReadMore: true, bannerBgHeight: 480 }),

  computed: {
    actualText() {
      return this.$prismic.asText(this.slice.primary.paragraph) || "";
    },
    paragraph() {
      const type = path(["primary", "paragraph", [0], "type"], this.slice);
      const truncatedText =
        this.actualText.length > 300 && this.isReadMore == true
          ? this.actualText.slice(0, 300) + "..."
          : this.actualText;
      return [{ text: truncatedText, type }];
    },
    showReadMore() {
      return this.actualText.length > 300;
    },
    bannerBgImgSrc() {
      return path(["banner_bg_image", "url"], this.slice.primary);
    },
    bannerBgImgWidth() {
      return path(
        ["banner_bg_image", "dimensions", "width"],
        this.slice.primary
      );
    },
    bgStyle() {
      if (!this.bannerBgImgSrc) {
        return null;
      }

      const imgUrl = this.$img(this.bannerBgImgSrc, {
        height: this.bannerBgHeight,
        width: this.imageWidth,
      });

      return { backgroundImage: `url(${imgUrl})` };
    },
  },
};
