export default {
  methods: {
    getCollectionRules({ appliedDisjunctively, rules: rawRules = [] } = {}) {
      if (rawRules?.length === 0) {
        const errMessage = `No rules in shopify admin are defined for collection: ${this.$route.params.handle}`;
        const err = new Error(errMessage);
        this.$bugsnag.notify(err);
      }

      const rules = this.normaliseCollectionRules(rawRules);
      const numericOperators = ["<", ">"];
      const hideFilters = [];
      let numericFilters = [];
      const disjunctivefacetFilters = {};
      const facetFilters = {};

      for (let iteration = 0; iteration < rules.length; iteration++) {
        const { column, relation, condition } = rules[iteration];
        hideFilters.push(column);

        if (numericOperators.includes(relation)) {
          numericFilters.push(`${column} ${relation} ${condition}`);
        } else if (appliedDisjunctively) {
          disjunctivefacetFilters[column] = [
            ...(disjunctivefacetFilters[column] || []),
            condition,
          ];
        } else {
          facetFilters[column] = [...(facetFilters[column] || []), condition];
        }
      }

      return {
        facetFilters,
        disjunctivefacetFilters,
        numericFilters,
      };
    },
    normaliseCollectionRules(rules) {
      const numericOperators = ["greater_than", "less_than"];

      return rules.flatMap(({ column, relation, condition }) => {
        column = this.resolveRealFacetName(column.toLowerCase());
        relation = numericOperators.includes(relation.toLowerCase())
          ? this.resolveRealMathOperator(relation.toLowerCase())
          : relation.toLowerCase();

        if (column === "tags" && condition.includes(":")) {
          const [key, value] = condition.split(":");

          return key === "size"
            ? [
                { column: "options.size", relation, condition: value },
                { column: `named_tags.${key}`, relation, condition: value },
              ]
            : [{ column: `named_tags.${key}`, relation, condition: value }];
        }

        return { column, relation, condition };
      });
    },
    resolveRealFacetName(facet) {
      switch (facet) {
        case "variant_inventory":
          return "inventory_quantity";
        case "variant_price":
          return "price";
        case "tag":
          return "tags";
        default:
          return facet;
      }
    },
    resolveRealMathOperator(operator) {
      switch (operator) {
        case "greater_than":
          return ">";
        case "less_than":
          return "<";
        default:
          return operator;
      }
    },
  },
};
