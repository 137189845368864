
import { pathOr, filter, allPass } from "ramda";

import ShopByStyleTile from "./ShopByStyleTile";

const pathToCollection = ["link", "body", [0], "variation", "primary"];
const filterItems = filter(
  allPass([
    pathOr(false, ["linkLabel", [0], "text"]),
    pathOr(false, [...pathToCollection, "title", [0], "text"]),
    pathOr(false, [...pathToCollection, "paragraph", [0], "text"]),
    pathOr(false, [...pathToCollection, "banner_bg_image"]),
  ])
);

export default {
  name: "ShopByStyle",

  components: { ShopByStyleTile },

  props: { slice: { type: Object, required: true } },

  computed: {
    items() {
      return filterItems(this.slice.fields);
    },
    tiles() {
      if (this.items.length > 0) {
        let [firstItem, ...rest] = this.items;
        rest.splice(2, 0, firstItem);
        return rest.slice(0, 5);
      }
      return [];
    },
    bigTile() {
      return this.tiles.slice(0, 1)[0];
    },
    smallTiles() {
      let data = this.tiles.slice();
      return data.slice(1);
    },
  },
};
