
import ShopByOfferTile from "./ShopByOfferTile";

export default {
  name: "ShopByOffer",

  components: { ShopByOfferTile },

  props: { slice: { type: Object, required: true } },
};
