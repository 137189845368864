import { render, staticRenderFns } from "./OfferSection.vue?vue&type=template&id=3d53c5a4&scoped=true&"
import script from "./OfferSection.vue?vue&type=script&lang=js&"
export * from "./OfferSection.vue?vue&type=script&lang=js&"
import style0 from "./OfferSection.vue?vue&type=style&index=0&id=3d53c5a4&prod&lang=postcss&scoped=true&"
import style1 from "./OfferSection.vue?vue&type=style&index=1&id=3d53c5a4&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d53c5a4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsRichText: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/cms/CmsRichText.vue').default})
