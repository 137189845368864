
import MediaWithHeadingItem from "./MediaWithHeadingItem";

export default {
  components: { MediaWithHeadingItem },

  props: {
    primary: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    title: { type: String, default: "" },
  },
};
