
import { pathOr, split, last, pipe, replace } from "ramda";

export default {
  props: { field: { type: Object, default: () => {} } },

  computed: {
    provider() {
      return pathOr("", ["provider_name"], this.field);
    },
    mediaUrl() {
      return pathOr("", ["embed_url"], this.field);
    },
    thumbnailUrl() {
      return pathOr("", ["thumbnail_url"], this.field);
    },
    isYoutube() {
      return this.provider.toLowerCase() === "youtube";
    },
    videoId() {
      return pipe(split("/"), last, replace(/watch\?v=/gi, ""))(this.mediaUrl);
    },
  },
};
