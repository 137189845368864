// Code generated by Slice Machine. DO NOT EDIT.
import HeroBannerV2 from "./HeroBannerV2";
import MemberProgramV2 from "./MemberProgramV2";
import ProductCatalogueV2 from "./ProductCatalogueV2";
import HtmlSectionV2 from "./HtmlSectionV2";
import TextBlockV2 from "./TextBlockV2";
import ButtonGalleryV2 from "./ButtonGalleryV2";
import MediaGridV2 from "./MediaGridV2";
import CardMediaGridV2 from './CardMediaGridV2';
import WorkWithUs from './WorkWithUs';
import CampaignBannerV2 from './CampaignBannerV2';
import BundleProductsV2 from './BundleProductsV2';
import ImageSectionV2 from './ImageSectionV2';

export const components = {
HeroBannerV2,
MemberProgramV2,
ProductCatalogueV2,
HtmlSectionV2,
TextBlockV2,
ButtonGalleryV2,
MediaGridV2,
CardMediaGridV2,
WorkWithUs,
CampaignBannerV2,
bundle_products_v2: BundleProductsV2,
image_section_v2:ImageSectionV2
};
