
import { mapState } from "vuex";

import ProductsFetch from "./ProductsFetch";

export default {
  components: { ProductsFetch },

  props: { slice: { type: Object, required: true } },

  data: () => ({ products: [] }),

  computed: mapState("app", ["recommendedWidgets"]),

  watch: {
    recommendedWidgets: {
      handler(value) {
        if (value.length > 0) {
          this.products = this.getOptimizelyProducts(value);
        }
      },
      immediate: true,
    },
  },

  methods: {
    getOptimizelyProducts(widgets) {
      const currentWidget = this.slice.primary?.widget ?? "homeWidget";

      return widgets
        .filter(({ widget }) => widget === currentWidget)
        .flatMap(({ recs }) => recs)
        .slice(0, this.slice.primary?.max_products_limit ?? undefined)
        .map(
          ({ prices, img, url, id, refCode, title, attributes = {} } = {}) => {
            const productURL = url ? new URL(url) : {};
            const handle = productURL.pathname?.split("/")?.pop();
            const variantId = productURL.searchParams?.get("variant");
            const [productTitle, variantTitle] = title.split(",");

            return {
              handle,
              recId: id,
              title: productTitle,
              id: attributes["product-id"],
              brandList: [attributes.brand],
              collections: [],
              images: [{ w_288_h_288: img }],
              featuredImage: { id: img, src: img, w_288_h_288: img },
              options: [],
              variants: [
                {
                  id: variantId,
                  variantTitle,
                  sku: refCode,
                  price: prices.AUD.salePrice,
                  compareAtPrice: prices.AUD.unitPrice,
                },
              ],
            };
          }
        );
    },
  },
};
