
import SwiperClass, { Navigation, Pagination } from "swiper";

export default {
  props: { items: { type: Array, default: () => [] } },

  data: () => ({ showNav: false, swiper: null }),

  computed: {
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        spaceBetween: 20,
        breakpoints: {
          1200: {
            slidesPerView: 3,
          },
        },
        loop: false,
        nested: true,
        loopFillGroupWithBlank: false,
        modules: [Navigation, Pagination],
        pagination: {
          el: ".video-swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".video-swiper-next",
          prevEl: ".video-swiper-prev",
          disabledClass: "swiper-button-disabled",
        },
      };
    },
  },

  mounted() {
    this.swiper = new SwiperClass(".swiper.video-swiper", this.swiperOptions);
  },

  destroyed() {
    this.swiper?.destroy?.();
  },
};
