
import { filter, propEq } from "ramda";
import { mapGetters } from "vuex";

const getSizes = filter(propEq("name", "Size"));

export default {
  props: {
    options: { type: Array, default: () => [] },
    handle: { type: String, default: "" },
  },
  computed: {
    ...mapGetters("products", ["getProductFetchingStatus"]),
    sizeOptions() {
      return getSizes(this.options);
    },
    hasSizes() {
      return this.sizeOptions.length != 0;
    },
    sizesValuesCount() {
      return this.sizeOptions[0].values.length;
    },
    sizesLabel() {
      return `${this.sizesValuesCount} ${
        this.sizesValuesCount == 1 ? "size" : "sizes"
      }`;
    },
  },
};
