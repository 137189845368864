
import calculateFlexBasis from "~/mixins/calculateFlexBasisMixin";

export default {
  name: "ButtonGallery",

  mixins: [calculateFlexBasis],

  props: { slice: { type: Object, required: true } },
};
