import { render, staticRenderFns } from "./InstagramFeedItem.vue?vue&type=template&id=66d63e08&"
import script from "./InstagramFeedItem.vue?vue&type=script&lang=js&"
export * from "./InstagramFeedItem.vue?vue&type=script&lang=js&"
import style0 from "./InstagramFeedItem.vue?vue&type=style&index=0&id=66d63e08&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconInstagram: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/dynamic/IconInstagram.vue').default,AppImg: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppImg.vue').default})
