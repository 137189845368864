
import { gql } from "graphql-tag";
import { mapState } from "vuex";

import { memberProgram } from "~/slices/MemberProgram/fragment";
import { nextDayDelivery } from "~/slices/NextDayDelivery/fragment";
import { textOverImage } from "~/slices/TextOverImage/fragment";
import { offersSection } from "~/slices/OffersSection/fragment";
import { offersSectionV2 } from "~/slices/OffersSectionV2/fragment";
import { videoBanner } from "~/slices/VideoBanner/fragment";
import { membersProgramV2 } from "~/slices/MemberProgramV2/fragment";
import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";
import { colors } from "~/colors.config";
import linkHandlerMixin from "~/mixins/linkClickHandlerMixin";

const CAMPAIGN_SALE_QUERY = gql`
  query campaign_sale1($uid: String!, $lang: String!) {
    campaign_sale1(uid: $uid, lang: $lang) {
      title
      bg_image
      bg_color
      sale_link {
        ...documentLink
        ...externalLink
      }
      button_label
      button_label_color
      button_color
      button_link {
        ...documentLink
      }
      show_on_membership_page_only
      slices: body {
        ...offersSection
        ...offersSectionV2
        ...nextDayDelivery
        ...memberProgram
        ...textOverImage
        ...videoBanner
        ...membersProgramV2
      }
    }
  }
  ${nextDayDelivery}
  ${memberProgram}
  ${textOverImage}
  ${offersSection}
  ${offersSectionV2}
  ${videoBanner}
  ${documentLink}
  ${externalLink}
  ${membersProgramV2}
`;

export default {
  mixins: [linkHandlerMixin],

  inject: {
    pageType: { default: "" },
  },

  props: {
    uid: { type: String, required: true },
    index: { type: Number, default: 0 },
  },

  data: () => ({ campaignSale: null, slices: [] }),

  async fetch() {
    const { campaign_sale1: data } = await this.$getPrismicData({
      query: CAMPAIGN_SALE_QUERY,
      variables: { uid: this.uid },
    });

    const { slices, ...rest } = data ?? {};

    this.slices = slices ? [slices?.[0]] : [];
    this.campaignSale = rest;
  },

  fetchOnServer() {
    return this.index === 0;
  },

  computed: {
    ...mapState("app", ["windowWidth"]),
    ctaLink() {
      const saleLink = this.campaignSale?.sale_link;
      return saleLink ? this.$prismic.asLink(saleLink) : null;
    },
    bgImage() {
      const smImage = this.campaignSale?.bg_image?.sm;
      const tabImage = this.campaignSale?.bg_image?.tablet;

      return this.isMobile && smImage
        ? smImage
        : this.isTablet && tabImage
        ? tabImage
        : this.campaignSale?.bg_image;
    },
    bgColor() {
      return this.campaignSale?.bg_color;
    },
    calculateBannerHeight() {
      const height =
        (this.bgImage.dimensions.height / this.bgImage.dimensions.width) *
        this.windowWidth;
      return {
        height: `${height}px`,
      };
    },
    bgStyle() {
      if (this.bgImage) {
        return {
          backgroundImage: `url('${this.$img(this.bgImage.url)}')`,
        };
      }

      if (this.bgColor) {
        return { backgroundColor: this.bgColor };
      }

      return { backgroundColor: colors.campaign.defaultBg };
    },
    styles() {
      return {
        ...this.calculateBannerHeight,
        ...this.bgStyle,
      };
    },
    showOnMembershipPageOnly() {
      return this.campaignSale?.show_on_membership_page_only ?? false;
    },
  },
};
