
import { path } from "ramda";

export default {
  props: {
    facetTitle: { type: Array, required: true },
    facetValue: { type: Object, required: true },
  },

  computed: {
    isBrand() {
      return this.$prismic.asText(this.facetTitle).toLowerCase() === "brand";
    },
    imgProps() {
      const img = path(["icon"], this.facetValue);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        width: path(["dimensions", "width"], img),
        height: path(["dimensions", "height"], img),
      };
    },
  },
};
