
export default {
  props: {
    item: { type: Object, default: () => {} },
  },

  data() {
    return { isPlaying: this.item.autoplay, isVideoVisible: false };
  },

  computed: {
    videoItems() {
      return this.items.filter(item => item.internal_video);
    },
  },

  methods: {
    playVideo() {
      this.$refs["video-item"]?.play?.();
    },
    pauseVideo() {
      this.$refs["video-item"]?.pause?.();
    },
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.isVideoVisible = true;
      }
    },
  },
};
