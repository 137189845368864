
import { mapActions } from "vuex";
import SwiperClass, { Navigation } from "swiper";

import ShopifyProductTile from "~/components/common/AppShopifyProductTile";

export default {
  components: { ShopifyProductTile },

  props: {
    products: { type: Array, required: true },
    sliceTitle: { type: String, required: true },
    swiperClass: { type: String, default: "" },
  },

  data: () => ({ swiper: null }),

  computed: {
    swiperOptions() {
      return {
        slidesPerGroup: 2,
        breakpoints: {
          // when window width is >= 767
          767: {
            slidesPerGroup: 4,
          },
        },
        slidesPerView: "auto",
        loopFillGroupWithBlank: false,
        modules: [Navigation],
        navigation: {
          nextEl: `.${this.swiperClass}-next`,
          prevEl: `.${this.swiperClass}-prev`,
        },
      };
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      `.swiper.${this.swiperClass}`,
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },

  methods: {
    ...mapActions("recommendations", ["trackBeacon"]),
    async handleClick(navType) {
      const source = this.products[0]?.source;
      if (!source?.includes("searchspring-")) {
        return;
      }

      const [, tag] = source.split("searchspring-");
      if (navType === "prev") {
        await this.trackBeacon({ type: "click", tag });
        return;
      }

      if (navType === "next") {
        // Add setTimeout so that all products are in visible list for current impression
        setTimeout(() => {
          this.trackBeacon({ type: "clickImpression", tag });
        }, 250);
      }
    },
  },
};
