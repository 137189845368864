
import { mapGetters } from "vuex";
import { AisSortBy } from "vue-instantsearch";
import ClickOutside from "vue-click-outside";

export default {
  components: { AisSortBy },

  directives: { ClickOutside },

  data: () => ({ active: false }),

  computed: mapGetters(["sortOrders"]),

  methods: {
    getRefinementOptions(items, currentRefinement) {
      return items.filter(({ value }) => value !== currentRefinement);
    },
    getRefinementLabel(items, currentRefinement) {
      const item = items.find(item => currentRefinement == item.value);
      return item ? item.label : "";
    },
    handleItemClick(refine, item) {
      refine(item.value);
      this.active = false;
    },
    hide() {
      this.active = false;
    },
  },
};
