
export default {
  name: "ScriptSection",

  props: { slice: { type: Object, required: true } },

  mounted() {
    let tag = document.createElement("script");
    tag.setAttribute("id", this.slice.primary.id);
    tag.setAttribute("src", this.slice.primary.src);
    this.$refs["script-section"]?.append?.(tag);
  },
};
