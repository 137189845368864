import { render, staticRenderFns } from "./ImageSwiper.vue?vue&type=template&id=99a30612&scoped=true&"
import script from "./ImageSwiper.vue?vue&type=script&lang=js&"
export * from "./ImageSwiper.vue?vue&type=script&lang=js&"
import style0 from "./ImageSwiper.vue?vue&type=style&index=0&id=99a30612&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99a30612",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperNav: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/swiper-nav/SwiperNav.vue').default})
