
import SwiperClass from "swiper";

import TopCategoryTile from "./TopCategoryTile";

export default {
  components: { TopCategoryTile },

  props: { fields: { type: Array, default: () => {} } },

  data: () => ({ swiper: null }),

  computed: {
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        loop: false,
        loopFillGroupWithBlank: false,
      };
    },
  },

  mounted() {
    this.swiper = new SwiperClass(".swiper.m-top-category", this.swiperOptions);
  },

  destroyed() {
    this.swiper?.destroy?.();
  },
};
