
import { createWidgetMixin } from "vue-instantsearch";
import { connectRange } from "instantsearch.js/es/connectors";
import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import "vue-slider-component/dist-css/vue-slider-component.css";
import "vue-slider-component/theme/default.css";

import { formatMoney } from "~/utils";

export default {
  components: { VueSlider },

  mixins: [createWidgetMixin({ connector: connectRange })],

  props: {
    attribute: { type: String, default: "" },
    label: { type: String, default: "" },
  },

  data: () => ({ open: false }),

  computed: {
    widgetParams() {
      return { attribute: this.attribute };
    },
    values() {
      const [minValue, maxValue] = this.state.start;
      const { min: minRange, max: maxRange } = this.range;

      return {
        min:
          minValue !== -Infinity && minValue !== minRange
            ? minValue
            : undefined,
        max:
          maxValue !== Infinity && maxValue !== maxRange ? maxValue : undefined,
      };
    },
    range() {
      return this.state.range;
    },
    show() {
      return !(
        this.state.range.min == 0 &&
        this.state.range.min == this.state.range.max
      );
    },
    canRefine() {
      return (
        this.state && Boolean(this.range) && this.range.min !== this.range.max
      );
    },
    hasRefinements() {
      if (this.state) {
        const [minValue, maxValue] = this.state.start;
        return minValue !== -Infinity || maxValue !== Infinity;
      }
      return false;
    },
  },

  watch: {
    hasRefinements(val) {
      this.open = this.open ? this.open : val;
    },
  },

  created() {
    this.$nuxt.$on("clear-all", val => {
      this.open = false;
    });
  },

  methods: {
    refine({ min, max }) {
      this.state.instantSearchInstance.helper.setPage(0);

      this.state.refine([min, max]);
    },
    toValue() {
      let min;
      if (this.values.min == null) {
        min = this.range.min;
      } else {
        // if min selected values, range is less than minimum range values, range
        // then min range will become min selected values, range
        min =
          this.values.min < this.range.min ? this.range.min : this.values.min;
      }

      let max;
      if (this.values.max == null) {
        max = this.range.max;
      } else {
        // if max selected values, range is greater than maximum range values, range
        // then max range will become max selected values, range
        max =
          this.values.max > this.range.max ? this.range.max : this.values.max;
      }
      return [min, max];
    },
    markValue() {
      return {
        [this.range.max]: this.range.max,
        [this.range.min]: this.range.min,
      };
    },
    formatPrice(amount) {
      return formatMoney(amount, "AUD");
    },
    activeStatus(val) {
      this.open = val;
    },
  },
};
