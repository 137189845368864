import { gql } from "graphql-tag";

export const memberProgram = gql`
  fragment memberProgram on Campaign_sale1BodyMember_program {
    type
    primary {
      program_title
      program_sustitle
      exclusive
    }
    fields {
      point_icon
      exclusive_point
    }
  }
`;
