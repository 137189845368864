
import CenterOffer from "./CenterOffer";
import AppShopButton from "~/components/common/AppShopButton";

export default {
  name: "OffersSectionV2",

  components: { CenterOffer, AppShopButton },

  props: {
    slice: { type: Object, required: true },
    context: { type: null, required: true },
  },
};
