
import ImageGridItem from "./ImageGridItem";

export default {
  components: { ImageGridItem },

  props: {
    items: { type: Array, default: () => [] },
    flexBasis: { type: Object, required: true },
    label: { type: String, default: "" },
  },
};
