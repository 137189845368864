
import { colors } from "~/colors.config";

export default {
  props: {
    field: { type: Object, default: () => {} },
    campaigns: { type: Array, default: () => [] },
    context: { type: null, required: true },
    noBg: { type: Boolean, default: false },
  },

  computed: {
    hasMoreSales() {
      return this.campaigns.length > 1;
    },
    hasSaleItems() {
      return this.campaigns.some(campaign => {
        return Object.values(campaign).some(sale => sale && sale[0].text);
      });
    },
    bgStyle() {
      return this.hasSaleItems
        ? {
            backgroundColor:
              this.field.sale_banner_color || colors.campaign.sale,
          }
        : {};
    },
  },
};
