
import { path } from "ramda";

import Accordian from "~/slices/Accordian";

export default {
  name: "AlternateGrid",

  components: { Accordian },

  props: { slice: { type: Object, required: true } },

  computed: {
    isRight() {
      return this.slice.primary.media_alignment.toLowerCase() === "right";
    },
    imageClass() {
      return this.slice.primary.image_align == "right" ? "lg:img-ml-auto" : "";
    },
    alignText() {
      return this.slice?.label == "" ? "prose" : `prose-${this.slice?.label}`;
    },
    imageWidth() {
      return this.slice.label == "snooze_now_pay_later"
        ? "img-w-auto img-ml-auto"
        : "img-w-full";
    },
    twoThirdContentArea() {
      return this.slice.label == "two_thirds_content_area";
    },
    imgProps() {
      const img = path(["image"], this.slice.primary);
      const imgDimensions = path(["dimensions"], img);
      const { width, height } = this.getImageDimensions(imgDimensions, 900);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        sizes: `md:100vw lg:${width}px`,
        width,
        height,
      };
    },
    imgIconProps() {
      const img = path(["optional_icon"], this.slice.primary);
      const imgDimensions = path(["dimensions"], img);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
  },
};
