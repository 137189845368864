
export default {
  props: { aspectRatio: { type: Number, default: 0.3 } },

  computed: {
    paddingTop() {
      return `${this.aspectRatio * 100}%`;
    },
  },
};
