
import AppContainer from "~/components/common/AppContainer.vue";

export default {
  name: "HtmlSection",

  components: { AppContainer },

  inject: { pageType: { default: "" } },

  props: { slice: { type: Object, required: true } },

  data: () => ({ newScript: null }),

  head() {
    return {
      script: [
        {
          src: this.slice.primary.src,
          defer: true,
          pbody: true,
          type: this.slice.primary.type,
          callback: () => {
            this.runScript();
          },
        },
      ],
    };
  },

  computed: {
    componentToRender() {
      return this.pageType === "Collection_page" ? "AppContainer" : "div";
    },
  },

  mounted() {
    if (!this.slice.primary.src) {
      this.runScript();
    }
  },

  destroyed() {
    if (this.newScript) {
      document.head.removeChild(this.newScript);
    }
  },

  methods: {
    runScript() {
      this.$nextTick(() => {
        this.reRunScript();
      });
    },

    /** 
    The script content added through innerHTML doesn't run 
    so we have to create a script tag with the old script content 
    */

    reRunScript() {
      // elm.innerHTML = html;
      // let element = this.$refs.htmlSection;
      Array.from(this.$refs.htmlSection.querySelectorAll("script")).forEach(
        oldScript => {
          this.newScript = document.createElement("script");
          this.newScript.id = "html-section-script";
          Array.from(oldScript.attributes).forEach(attr =>
            this.newScript.setAttribute(attr.name, attr.value)
          );

          this.newScript.appendChild(
            document.createTextNode(oldScript.innerHTML)
          );

          document.head.appendChild(this.newScript);
          // oldScript.parentNode.replaceChild(this.newScript, oldScript);
        }
      );
    },
  },
};
