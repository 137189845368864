import { gql } from "graphql-tag";

export const membersProgramV2 = gql`
  fragment membersProgramV2 on Campaign_sale1BodyMember_program_v2 {
    type
    label
    variation {
      ... on Campaign_sale1BodyMember_program_v2Default {
        primary {
          offer_banner_color
          offer_title
          offer_title_color
          offer_text
          offer_condition
          offer_percentage
          offer_items
          plus_offer_prefix
          plus_offer
          plus_offer_color
          exclusive_text
          offer_disclaimer
        }
        items {
          exclusive_point
          point_icon
        }
      }
    }
  }
`;
