
import ProductsFetch from "./ProductsFetch";

export default {
  components: { ProductsFetch },

  props: {
    slice: { type: Object, required: true },
    getPrismicData: { type: Function, required: true },
  },

  data: () => ({ products: [] }),

  async fetch() {
    const slice = await this.getPrismicData();

    this.products = (slice.variation?.items ?? [])
      .map(({ variant }) => variant)
      .filter(Boolean)
      .slice(0, this.slice.primary?.max_products_limit ?? undefined)
      .map(({ product, ...variant }) => ({ ...product, variants: [variant] }));
  },

  fetchOnServer: false,
};
