
export default {
  model: { prop: "checked", event: "change" },

  props: {
    checked: { type: Boolean, default: false },
    color: { type: String, default: "" },
    borderColor: { type: String, default: "" },
  },

  data() {
    return { isChecked: this.checked };
  },

  watch: {
    checked: {
      immediate: true,
      handler(value) {
        this.isChecked = value;
      },
    },
  },

  methods: {
    checkBoxClick() {
      this.isChecked = !this.isChecked;
      this.$emit("change");
    },
  },
};
