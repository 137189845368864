import { render, staticRenderFns } from "./PlpPriceRangeRefinement.vue?vue&type=template&id=13df2bf0&"
import script from "./PlpPriceRangeRefinement.vue?vue&type=script&lang=js&"
export * from "./PlpPriceRangeRefinement.vue?vue&type=script&lang=js&"
import style0 from "./PlpPriceRangeRefinement.vue?vue&type=style&index=0&id=13df2bf0&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppExpansionPanel: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppExpansionPanel.vue').default})
