
const IMG_WIDTH = 402;

export default {
  props: { field: { type: Object, default: () => {} } },

  computed: {
    textField() {
      return [this.field?.description?.find?.(x => x.type === "paragraph")];
    },
    imgProps() {
      const featureImg = this.field?.feature_image;
      const tileImg = this.field?.feature_image?.tile_image;

      return {
        src: tileImg?.url ?? featureImg?.url,
        alt: tileImg?.alt ?? featureImg?.alt,
        width: IMG_WIDTH,
        height: IMG_WIDTH,
        fit: "cover",
        modifiers: { crop: "faces" },
      };
    },
  },

  methods: {
    handleClick() {
      const blogLink = this.$prismic.asLink(this.field);
      this.$router.push(blogLink);
    },
  },
};
