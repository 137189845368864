
import { tagableMixin } from "~/mixins";

export default {
  mixins: [tagableMixin],

  props: {
    product: { type: Object, required: true },
    selectedVariant: { type: Object, required: true },
    minPriceVariant: { type: Object, default: () => {} },
    maxDiscount: { type: [Number, String], default: 0 },
    priorityCampaign: { type: Object, default: () => {} },
  },

  computed: {
    compareAtPrice() {
      return (this.minPriceVariant ?? this.selectedVariant)?.compareAtPrice;
    },
    price() {
      return (this.minPriceVariant ?? this.selectedVariant).price;
    },
    tags() {
      return this.product?.tags ?? [];
    },
    saleTagBgColor() {
      return this.priorityCampaign?.saleColor ?? "";
    },
    saleTagText() {
      return this.priorityCampaign?.saleTileTitle;
    },
    saleTagTextMobile() {
      return this.priorityCampaign?.saleTileTitleMobile;
    },
    showCompareAtPrice() {
      return this.canShowCompareAtPrice(
        this.priorityCampaign,
        this.compareAtPrice,
        this.price
      );
    },
    showRRPText() {
      return this.priorityCampaign?.showRRPText ?? true;
    },
    showEnhancedUI() {
      return this.priorityCampaign?.enhancedUI;
    },
    campaignPrice() {
      return this.price - (this.price * this.maxDiscount) / 100;
    },
    enhancedUIRrpPrice() {
      return this.showCompareAtPrice && this.maxDiscount == 0
        ? this.campaignPrice
        : this.price;
    },
  },
};
