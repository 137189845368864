import { render, staticRenderFns } from "./LoadingIcon.vue?vue&type=template&id=52de1d62&scoped=true&"
var script = {}
import style0 from "./LoadingIcon.vue?vue&type=style&index=0&id=52de1d62&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52de1d62",
  null
  
)

export default component.exports