
import CardMediaWithSwiper from "./CardMediaWithSwiper";

export default {
  components: { CardMediaWithSwiper },

  props: { slice: { type: Object, required: true } },

  computed: {
    items() {
      return this.slice.items?.filter(field => field?.image);
    },
    hasImages() {
      return this.items?.every(item => item?.image);
    },
    title() {
      return this.slice.primary?.title[0]?.text;
    },
  },
};
