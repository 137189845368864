import { render, staticRenderFns } from "./ProductFilterPanel.vue?vue&type=template&id=125745dc&scoped=true&"
import script from "./ProductFilterPanel.vue?vue&type=script&lang=js&"
export * from "./ProductFilterPanel.vue?vue&type=script&lang=js&"
import style0 from "./ProductFilterPanel.vue?vue&type=style&index=0&id=125745dc&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "125745dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIconFilter: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconFilter.vue').default,PlpClearAllRefinements: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/plp/PlpClearAllRefinements.vue').default,PlpAllSelectedRefinements: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/plp/PlpAllSelectedRefinements.vue').default,ProductSorting: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/plp/ProductSorting.vue').default,SvgIconClose: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconClose.vue').default,AppContainer: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppContainer.vue').default,PlpAllRefinements: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/plp/PlpAllRefinements.vue').default,AppButton: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppButton.vue').default})
