import { render, staticRenderFns } from "./ProductTile.vue?vue&type=template&id=1c4a1627&"
import script from "./ProductTile.vue?vue&type=script&lang=js&"
export * from "./ProductTile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductTileImages: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/product-tile/ProductTileImages.vue').default,ProductTileTitle: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/product-tile/ProductTileTitle.vue').default,ProductTileColors: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/product-tile/ProductTileColors.vue').default,ProductTileSizes: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/product-tile/ProductTileSizes.vue').default,AppProductTilePrice: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppProductTilePrice.vue').default,AppButton: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppButton.vue').default})
