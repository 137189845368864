
import getCmsSlice from "./get-cms-slice";
import { components } from "~/slices";
import { oldComponents } from "~/slices/old-slices";
import { camelize } from "~/utils";

export default {
  props: {
    slices: { type: Array, required: true },
    context: { type: null, default: undefined, required: false },
  },

  data: () => ({ components: { ...components, ...oldComponents } }),

  computed: {
    updatedSlices() {
      return this.slices.map(e => ({ ...e, slice_type: e.type }));
    },
  },

  methods: {
    resolver({ sliceName }) {
      return sliceName
        ? getCmsSlice(
            sliceName,
            this.components[sliceName] ??
              this.components[camelize(sliceName, true)],
            this.noHydration
          )
        : sliceName;
    },
  },
};
