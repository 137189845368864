
export default {
  name: "CampaignBanner",

  inject: { pageType: { default: "" } },

  props: { slice: { type: Object, required: true } },

  computed: {
    isMemberPage() {
      return this.pageType === "Become_a_member";
    },
  },
};
