
export default {
  props: { item: { type: Object, required: true } },

  computed: {
    imgProps() {
      const img = this.$device.isMobileOrTablet
        ? this.item?.image?.mobile
        : this.item?.image?.desktop;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
  },
};
