
import { AisConfigure } from "vue-instantsearch";

import collectionRuleMixin from "~/mixins/collectionRuleMixin";

export default {
  components: { AisConfigure },

  mixins: [collectionRuleMixin],

  props: { collectionRuleSet: { type: Object, required: true } },

  computed: {
    facetFilters() {
      return this.getCollectionRules(this.collectionRuleSet);
    },
    facets() {
      const { facetFilters } = this.facetFilters;
      return Object.keys(facetFilters);
    },
    facetsRefinements() {
      const { facetFilters } = this.facetFilters;
      return facetFilters;
    },
    filterRefinement() {
      const { numericFilters } = this.facetFilters;

      return ['NOT tags:"algolia-ignore"', ...numericFilters].join(" AND ");
    },
    disjunctiveFacets() {
      const { disjunctivefacetFilters } = this.facetFilters;
      return Object.keys(disjunctivefacetFilters);
    },
    disjunctiveFacetsRefinements() {
      const { disjunctivefacetFilters } = this.facetFilters;
      return disjunctivefacetFilters;
    },
  },
};
