
import { mapState, mapGetters } from "vuex";
import { createWidgetMixin, AisStats } from "vue-instantsearch";
import { connectCurrentRefinements } from "instantsearch.js/es/connectors";
import { map, compose, flatten, length } from "ramda";

export default {
  components: { AisStats },

  mixins: [createWidgetMixin({ connector: connectCurrentRefinements })],

  props: { bannerConfig: { type: Object, required: true } },

  computed: {
    ...mapGetters(["shownFacets"]),
    ...mapState("app", ["isFilterPanelOpen"]),
    productListClass() {
      return this.isFilterPanelOpen ? "hidden  lg:block" : "";
    },
    refinementCount() {
      const getRefinementItems = ({ refinements, refine }) => {
        return refinements.map(refinement => ({ ...refinement, refine }));
      };
      const getCount = compose(length, flatten, map(getRefinementItems));

      return this.state ? getCount(this.state.items) : 0;
    },
    widgetParams() {
      return { includedAttributes: this.shownFacets.map(f => f.name) };
    },
  },

  methods: {
    getPagination({ productCount, nbHits }) {
      if (nbHits === 0) {
        return `No match found . Try selecting filters again`;
      }

      // const itemCount =
      //   page + 1 === nbPages ? nbHits : hitsPerPage * (page + 1);
      return `Showing ${productCount} of ${nbHits} products`;
    },
  },
};
