
export default {
  name: "MemberAlternateGrid",

  props: { slice: { type: Object, required: true } },

  computed: {
    isRight() {
      return this.slice.primary.media_alignment.toLowerCase() === "right";
    },
    imageClass() {
      return this.slice.primary.image_align == "right" ? "lg:img-ml-auto" : "";
    },
    imgProps() {
      const img = this.slice.primary?.image;
      const imgDimensions = img?.dimensions;
      const { width, height } = this.getImageDimensions(imgDimensions, 900);
      return {
        src: img?.url,
        alt: img?.alt,
        sizes: `md:100vw lg:${width}px`,
        width,
        height,
      };
    },
    imgIconProps() {
      const img = this.$device.isMobileOrTablet
        ? this.slice.primary?.optional_icon?.tablet ??
          this.slice.primary?.optional_icon
        : this.slice.primary?.optional_icon;
      const imgDimensions = img?.dimensions;
      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
  },
};
