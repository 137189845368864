
import InstagramFeedItem from "./InstagramFeedItem";
import InstagramModal from "./InstagramModal";

export default {
  components: { InstagramModal, InstagramFeedItem },

  props: { primary: { type: Object, default: () => {} } },

  data: () => ({ gallery: [], showModal: false, index: null }),

  async fetch() {
    try {
      const { data } = await this.$axios.get(this.$config.instagramUrl);
      this.gallery = data.items;
    } catch (err) {
      this.$bugsnag.notify(err);
    }
  },

  fetchOnServer: false,

  methods: {
    onImageClick(index) {
      this.index = index;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
