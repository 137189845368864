
import AccordionItem from "./AccordionItem";
import calculateFlexBasis from "~/mixins/calculateFlexBasisMixin";

export default {
  name: "Accordian",

  components: { AccordionItem },

  mixins: [calculateFlexBasis],

  props: { slice: { type: Object, required: true } },

  data: () => ({ open: false }),

  computed: {
    horizontalAccordian() {
      return this.slice.label == "horizontal-accordian";
    },
  },

  methods: {
    clickHandler(index) {
      this.open = this.open === index ? null : index;
    },
  },
};
