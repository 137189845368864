import { render, staticRenderFns } from "./CollectionReview.vue?vue&type=template&id=f94f4f92&scoped=true&"
import script from "./CollectionReview.vue?vue&type=script&lang=js&"
export * from "./CollectionReview.vue?vue&type=script&lang=js&"
import style0 from "./CollectionReview.vue?vue&type=style&index=0&id=f94f4f92&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f94f4f92",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RatingStars: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/RatingStars.vue').default})
