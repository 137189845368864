import { gql } from "graphql-tag";

import { documentLink } from "~/app/prismic/prismic-fragments";

export const BlogDetail = gql`
  fragment BlogDetail on Blog {
    ...documentLink
    title
    feature_image
    description
  }
  ${documentLink}
`;

export const getListOfArticles = pageType => gql`
  fragment ${pageType}_list_of_articles on ${pageType}BodyList_of_articles {
    type
    primary @skip(if: $lazy) {
      title
    }
    fields @include(if: $lazy) {
      blog_link {
        ... BlogDetail
      }
    }
  }
  ${BlogDetail}
`;
