
export default {
  props: { slice: { type: Object, required: true } },

  computed: {
    campaignBannerUid() {
      return this.slice?.primary?.campaign_sale._meta.uid;
    },
  },
};
