
import yotpoWidgetMixin from "~/mixins/yotpoWidgetMixin";

export default {
  name: "YotpoWidget",

  mixins: [yotpoWidgetMixin],

  inject: { pageType: { default: "" } },

  props: { slice: { type: Object, required: true } },

  head() {
    return {
      script: [
        {
          src: this.slice.primary.script_source,
          body: true,
          async: true,
          callback: () => this.checkForYotpo(),
        },
      ],
    };
  },

  computed: {
    isMember() {
      return this.pageType === "Become_a_member";
    },
  },

  mounted() {
    this.checkForYotpo();
  },
};
