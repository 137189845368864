
import ClickOutside from "vue-click-outside";

export default {
  directives: { ClickOutside },

  props: { item: { type: Object, default: () => {} } },

  data: () => ({
    show: false,
  }),

  computed: {
    description() {
      if (this.item.content.length > 150) {
        return this.item.content.slice(0, 150) + "...";
      }
      return this.item.content;
    },
    showReadMore() {
      if (this.item.content.length > 150) {
        return true;
      }
      return false;
    },
  },

  methods: {
    hide() {
      this.show = false;
    },
  },
};
