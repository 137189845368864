
import MediaWithBgColor from "./MediaWithBgColor";
import MediaWithBgImage from "./MediaWithBgImage";
import MediaWithHeading from "./MediaWithHeading";
import TitleBanner from "./TitleBanner";
import CallToAction from "./CallToAction";
import AboutUs from "./AboutUs";
import ImageBanner from "./ImageBanner";
import MediaGridWithBanner from "./MediaGridWithBanner";

export default {
  components: {
    MediaWithBgColor,
    MediaWithBgImage,
    MediaWithHeading,
    TitleBanner,
    CallToAction,
    AboutUs,
    ImageBanner,
    MediaGridWithBanner,
  },

  props: { slice: { type: Object, required: true } },

  computed: {
    items() {
      return this.slice.items?.filter(field => field?.image);
    },
    hasImages() {
      return this.items?.every(item => item?.image);
    },
    title() {
      return this.slice.primary?.title && this.slice.primary?.title[0]?.text;
    },
  },
};
