
import AppContainer from "~/components/common/AppContainer.vue";

export default {
  name: "TextBlock",

  components: { AppContainer },

  inject: { pageType: { pageType: { default: "" } } },

  props: { slice: { type: Object, required: true } },

  computed: {
    componentToRender() {
      return this.isPlp || this.isClp ? "AppContainer" : "div";
    },
    theme() {
      return this.slice.label
        ? `prose prose-${this.slice.label} lg:prose-${this.slice.label}-lg ${this.slice.label}`
        : "prose";
    },
    isPlp() {
      return this.pageType === "Product_list_page";
    },
    isClp() {
      return this.pageType === "Collection_page";
    },
    isL2Plp() {
      return this.pageType === "L2_product_list_page";
    },
    isPlpOrL2PlpOrClp() {
      return this.isL2Plp || this.isPlp || this.isClp;
    },
    alignCenter() {
      return this.slice?.label?.includes("center");
    },
  },
};
