
import TextAlternateGridItem from "./TextAlternateGridItem";
import calculateFlexBasis from "~/mixins/calculateFlexBasisMixin";

export default {
  name: "TextAlternateGrid",

  components: { TextAlternateGridItem },

  mixins: [calculateFlexBasis],

  props: { slice: { type: Object, required: true } },

  computed: {
    textAlignment() {
      return this.slice.label == "center" || this.slice.label === "velocity"
        ? "text-center"
        : "text-left";
    },
  },
};
