import { gql } from "graphql-tag";

import { documentLink } from "~/app/prismic/prismic-fragments";

export const offersSectionV2 = gql`
  fragment offersSectionV2 on Campaign_sale1BodyOffers_section_v2 {
    type
    label
    primary {
      offer_title
      offer_title_color
      plus_offer_prefix
      plus_offer
      plus_offer_color
      offer_disclaimer
      offer_banner_color
      has_gradient
      start_color
      end_color
      gradient_direction
    }
    fields {
      offer_text
      offer_condition
      offer_percentage
      offer_items
    }
  }
  ${documentLink}
`;
