import { gql } from "graphql-tag";

export const nextDayDelivery = gql`
  fragment nextDayDelivery on Campaign_sale1BodyNext_day_delivery {
    type
    primary {
      ndd_title
      ndd_subtitle
      ndd_terms
    }
  }
`;
