
export default {
  props: { item: { type: Object, default: () => {} } },

  data: () => ({ isPlaying: false, isVideoVisible: false }),

  methods: {
    playVideo() {
      this.$refs["video-item"]?.play?.();
    },
    pauseVideo() {
      this.$refs["video-item"]?.pause?.();
    },
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.isVideoVisible = true;
      }
    },
  },
};
