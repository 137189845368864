export default {
  methods: {
    handleLinkClick(toLink) {
      // Check if the link url contains http or https
      // no http means relative path
      // so we can push on browser history
      if (!/^http/.test(toLink)) {
        this.$router.push(toLink);
        return;
      }

      const fullURL = new URL(toLink);
      // origin is same means relative path
      // so we can push on browser history
      if (fullURL.origin === window.location.origin) {
        console.log({ looo: fullURL.pathname });
        this.$router.push(fullURL.pathname);
        return;
      }

      // else absolute url to some other app
      // we have to reload the page
      window.location.href = toLink;
    },
  },
};
