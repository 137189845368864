
export default {
  props: { slice: { type: Object, required: true } },

  computed: {
    bannerImgProps() {
      const bannerImg = this.slice.primary?.title_image;
      const img = this.$device.isMobile
        ? bannerImg?.mobile
        : bannerImg?.desktop;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
    iconProps() {
      const icon = this.slice.items[0]?.icon;
      const img = this.$device.isMobile ? icon?.mobile : icon?.desktop;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
  },
};
