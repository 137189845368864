
import { path } from "ramda";

export default {
  props: { images: { type: Array, required: true } },

  data: () => ({ imageIndex: 0 }),

  computed: {
    hasMoreImages() {
      return this.images.length > 1;
    },
    imageVisible() {
      return this.images[this.imageIndex];
    },
    imgProps() {
      return {
        src: path(["url"], this.imageVisible),
        alt: path(["altText"], this.imageVisible),
        width: 500,
        height: 500,
        provider: "ipx",
      };
    },
  },

  methods: {
    clickSwiper(index) {
      if (this.imageIndex == 0 && index == -1) {
        this.imageIndex = this.images.length - 1;
      } else {
        this.imageIndex = (this.imageIndex + index) % this.images.length;
      }
    },
  },
};
