
import { path, pathOr } from "ramda";

import { colors } from "~/colors.config";

export default {
  name: "CallToAction",

  props: { slice: { type: Object, required: true } },

  computed: {
    ctaStyle() {
      const textColor = pathOr(
        colors.gray[900],
        ["text_color_palette"],
        this.slice.primary
      );
      const bgColor = pathOr(
        colors.gray[400],
        ["background_color_palette"],
        this.slice.primary
      );
      const bannerImage = path(["bg_image"], this.slice.primary);
      const imgSrc = path(["url"], bannerImage);

      if (imgSrc == null) {
        return { backgroundColor: bgColor, color: textColor };
      }

      const imgDimensions = path(["dimensions"], bannerImage);
      const { width, height } = this.getImageDimensions(imgDimensions);
      return {
        color: textColor,
        backgroundImage: `url('${this.$img(imgSrc, { width, height })}')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        minHeight: `${height} px`,
      };
    },
    imgProps() {
      const img = path(["icon"], this.slice.primary);
      const imgDimensions = path(["dimensions"], img);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        ...this.getImageDimensions(imgDimensions, 600),
      };
    },
  },
};
