// This file is generated by Slice Machine. Update with care!

import ListOfArticles from "./ListOfArticles";
import CallToAction from "./CallToAction";
import NewsSubscription from "./NewsSubscription";
import TopCategory from "./TopCategory";
import ShopByStyle from "./ShopByStyle";
import Instagram from "./Instagram";
import HeroCarousel from "./HeroCarousel";
import CustomerReview from "./CustomerReview";
import OffersSection from "./OffersSection";
import OffersSectionV2 from "./OffersSectionV2";
import MemberProgram from "./MemberProgram";
import NextDayDelivery from "./NextDayDelivery";
import TextOverImage from "./TextOverImage";
import VideoBanner from "./VideoBanner";
import ListOfFacets from "./ListOfFacets";
import HeroBanner from "./HeroBanner";
import TextBlock from "./TextBlock";
import HeroSection from "./HeroSection";
import ListOfProducts from "./ListOfProducts";
import CampaignBanner from "./CampaignBanner";
import MemberBanner from "./MemberBanner";
import MemberEventOffer from "./MemberEventOffer";
import MemberAlternateGrid from "./MemberAlternateGrid";
import YotpoWidget from "./YotpoWidget";
import ImageSection from "./ImageSection";
import Text from "./Text";
import HtmlSection from "./HtmlSection";
import MattressBrandSection from "./MattressBrandSection";
import IconGallery from "./IconGallery";
import TextAlternateGrid from "./TextAlternateGrid";
import ButtonGallery from "./ButtonGallery";
import MediaAlternateGrid from "./MediaAlternateGrid";
import AlternateGrid from "./AlternateGrid";
import Accordian from "./Accordian";
import CallToActionBanner from "./CallToActionBanner";
import StoreFinanceBlock from "./StoreFinanceBlock";
import QuickLinks from "./QuickLinks";
import SmallText from "./SmallText";
import ScriptSection from "./ScriptSection";
import FeedbackForm from "./FeedbackForm";
import SliderAlternateGrid from "./SliderAlternateGrid";
import ShopByOffer from "./ShopByOffer";
import MemberBenefits from "./MemberBenefits";

export const oldComponents = {
  ListOfArticles,
  CallToAction,
  NewsSubscription,
  TopCategory,
  ShopByStyle,
  Instagram,
  HeroCarousel,
  CustomerReview,
  OffersSection,
  OffersSectionV2,
  MemberProgram,
  NextDayDelivery,
  TextOverImage,
  VideoBanner,
  ListOfFacets,
  HeroBanner,
  TextBlock,
  HeroSection,
  ListOfProducts,
  CampaignBanner,
  MemberBanner,
  MemberEventOffer,
  MemberAlternateGrid,
  YotpoWidget,
  Text,
  ImageSection,
  HtmlSection,
  MattressBrandSection,
  IconGallery,
  TextAlternateGrid,
  ButtonGallery,
  MediaAlternateGrid,
  AlternateGrid,
  Accordian,
  CallToActionBanner,
  StoreFinanceBlock,
  QuickLinks,
  // rarely used
  SmallText,
  ScriptSection,
  FeedbackForm,
  SliderAlternateGrid,
  // end rarely used
  ShopByOffer,
  MemberBenefits,
};
