import { render, staticRenderFns } from "./CardMediaWithSwiper.vue?vue&type=template&id=44b5f941&scoped=true&"
import script from "./CardMediaWithSwiper.vue?vue&type=script&lang=js&"
export * from "./CardMediaWithSwiper.vue?vue&type=script&lang=js&"
import style0 from "./CardMediaWithSwiper.vue?vue&type=style&index=0&id=44b5f941&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b5f941",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperNav: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/swiper-nav/SwiperNav.vue').default})
