
import InternalVideoGrid from "./InternalVideoGrid";

export default {
  components: { InternalVideoGrid },

  props: {
    slice: { type: Object, required: true },
    flexBasis: { type: Object, required: true },
  },
};
