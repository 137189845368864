
import InstagramFeed from "./InstagramFeed";

export default {
  name: "InstagramSection",

  components: { InstagramFeed },

  props: { slice: { type: Object, required: true } },
};
