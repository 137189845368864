
import { colors } from "~/colors.config";

export default {
  props: { field: { type: Object, default: () => {} } },

  computed: {
    bgStyle() {
      return {
        backgroundColor:
          this.field.plus_background_color || colors.campaign.sale,
      };
    },
  },
};
