
export default {
  props: {
    tileIndex: { type: Number, required: true },
    item: { type: Object, default: () => {} },
  },

  computed: {
    primary() {
      return this.item.link.body[0].variation.primary;
    },
    img() {
      return this.primary?.banner_bg_image;
    },
    imgSrc() {
      return this.tileIndex === 2 ? this.img.url : this.img?.HomePage?.url;
    },
    imgWidth() {
      return this.tileIndex === 2
        ? this.img?.dimensions?.width
        : this.img?.HomePage?.dimensions?.width;
    },
    imgHeight() {
      return this.tileIndex === 2
        ? this.img?.dimensions?.height
        : this.img?.HomePage?.dimensions?.height;
    },
    imgSizes() {
      return this.tileIndex === 2 ? "md:100vw lg:100vw xl:1400px" : null;
    },
    imgProps() {
      return {
        src: this.imgSrc,
        alt: this.img.alt,
        width: this.imgWidth,
        height: this.imgHeight,
        sizes: this.imgSizes,
      };
    },
  },
};
