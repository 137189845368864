
import { createWidgetMixin } from "vue-instantsearch";
import {
  path,
  map,
  last,
  andThen,
  compose,
  splitEvery,
  unnest,
  otherwise,
  difference,
  isNil,
  isEmpty,
  equals,
} from "ramda";
import { gql } from "graphql-tag";
import { mapGetters, mapState } from "vuex";
import { connectInfiniteHits } from "instantsearch.js/es/connectors";
import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache";

import { gtmEventMixin } from "~/mixins";
import { groupByProp, getVariant } from "~/utils";
import { debounce } from "~/utils/underscore-utils";

const PRODUCT_DETAIL = gql`
  fragment PLPProductDetail on Product {
    id
    handle
    options {
      name
      values
    }
    variants(first: 20) {
      nodes {
        id
        sku
        title
        price {
          amount
        }
        compareAtPrice {
          amount
        }
        selectedOptions {
          name
          value
        }
        metafields(identifiers: [{ namespace: "snooze", key: "color_hex" }]) {
          key
          value
          type
        }
      }
    }
  }
`;

export default {
  mixins: [
    gtmEventMixin,
    createWidgetMixin({ connector: connectInfiniteHits }),
  ],

  inject: {
    pageType: { default: "" },
    uid: { default: "" },
  },

  props: {
    isRefined: { type: Boolean, default: false },
    bannerConfig: { type: Object, required: true },
  },

  data: () => ({
    fromServer: true,
    topBarHeight: 100,
    loadMoreClicked: false,
    shopifyProducts: [],
    shopifyProductsLoaded: false,
    cache: createInfiniteHitsSessionStorageCache(),
    debouncedRefinePrevious: () => {},
  }),

  computed: {
    ...mapGetters(["shownFacets"]),
    ...mapState("app", ["topBannerHeight"]),
    ...mapState("srp", ["productCount"]),
    widgetParams() {
      return { showPrevious: true, cache: this.cache };
    },
    items() {
      const hits = this.fromServer
        ? this.state?.currentPageHits ?? []
        : this.state?.hits ?? [];
      const totalHits = this.state?.results?.nbHits || null;
      const indexes = [];
      const { bannerIndex1, bannerIndex2 } = this.bannerIndexs;

      // Pushing positions for banner 1
      if (totalHits && bannerIndex1 !== -1) {
        for (let iTemp = bannerIndex1; iTemp < totalHits; iTemp += 18) {
          if (!indexes.includes(iTemp)) {
            indexes.push(iTemp);
          }
        }
      }

      // Pushing positions for banner 2
      if (totalHits && bannerIndex2 !== -1) {
        for (let jTemp = bannerIndex2; jTemp < totalHits; jTemp += 18) {
          if (!indexes.includes(jTemp)) {
            indexes.push(jTemp);
          }
        }
      }

      if (indexes.length > 0 && hits.length !== 0) {
        indexes.sort(function (a, b) {
          return a - b;
        });
        indexes.forEach(item => {
          if (
            hits[item]?.objectID !== "_banner_tile_" + item &&
            item <= hits.length - 1
          ) {
            hits?.splice(item, 0, {
              objectID: "_banner_tile_" + item,
            });
          }
        });
      }

      return hits;
    },
    bannerIndexs() {
      return this.isMobile
        ? {
            bannerIndex1: this.bannerConfig?.display_banners ? 6 : -1,
            bannerIndex2: this.bannerConfig?.display_banners ? 15 : -1,
          }
        : this.isMyDesktop || this.isDesktop
        ? {
            bannerIndex1: this.bannerConfig?.display_banners ? 6 : -1,
            bannerIndex2: this.bannerConfig?.display_banners ? 17 : -1,
          }
        : {};
    },
    isLastPage() {
      return this.state.isLastPage;
    },
    productIds() {
      return this.items.map(item => item.handle);
    },
    normalizeShopifyProducts() {
      return groupByProp("handle")(this.shopifyProducts);
    },
    showYotpoReviewOnPlp() {
      return this.$config.yotpoReviewOnPlp;
    },
    productsCount() {
      const products = this.items.filter(
        item => !item.objectID?.includes("_banner_tile_")
      );

      return products.length;
    },
  },

  watch: {
    async productIds(newProductIds, oldProductIds) {
      this.shopifyProductsLoaded = false;
      const differenceList = difference(newProductIds, oldProductIds);
      if (differenceList.length == 0) {
        return;
      }

      const newShopifyProducts = await this.getShopifyProducts(differenceList);
      // console.log({ newShopifyProducts });
      this.pushProducts(newShopifyProducts);

      this.$store.dispatch("products/setProductsFetching", {
        productHandles: differenceList,
        isFetching: false,
      });

      this.shopifyProductsLoaded = true;

      this.$optimizely.track({
        ...(this.pageType === "Product_search_page" && {
          type: "searchresults",
          searchResults: {
            term: this.$route.query.query,
            totalNumberOfResults: this.state.results.nbHits,
            pageNumber: this.state.results.page + 1,
            resultsOnPage: this.items.length,
            filters: this.getOptimizelyFilters(),
            topResults: this.items
              .slice(0, 3)
              .map(item => ({ refCode: item?.sku })),
          },
        }),
        ...(this.pageType !== "Product_search_page" && {
          type: "category",
          category: this.$route.params.handle,
        }),
      });
    },

    items: [
      function scrollOnItemsChange(newItems, oldItems) {
        if (
          this.loadMoreClicked &&
          newItems.length != oldItems.length &&
          oldItems.length != 0
        ) {
          // first element will be after last from
          // previous elements list
          this.scrollToBack(last(this.$refs.productElements), [
            "$el",
            "nextElementSibling",
          ]);
        }
      },
      function addCountToStore(newItems, oldItems) {
        if (newItems.length == 0 && oldItems.length == 0) {
          // Discard initial zero count setting
          return;
        }

        this.$store.dispatch("srp/setProductCount", { count: newItems.length });
      },
      function addItemsToGtm(newItems, oldItems) {
        const differenceList = difference(newItems, oldItems);
        const listWithoutBanners = differenceList.filter(
          item => !item?.objectID?.includes("_banner_tile_")
        );

        if (listWithoutBanners.length !== 0) {
          this.triggerGtmEvent(listWithoutBanners);
        }
      },
    ],
    "$route.query": {
      handler(newQuery, oldQuery) {
        this.watchQuery(newQuery, oldQuery);
      },
    },
  },

  created() {
    this.debouncedRefinePrevious = debounce(this.refinePrevious, 500);
  },

  mounted() {
    this.fromServer = false;
  },

  methods: {
    pushProducts(products) {
      products.forEach(element => {
        this.$set(this.shopifyProducts, this.shopifyProducts.length, element);
      });
    },
    getShopifyProducts(productIds) {
      const getProducts = products => {
        const queries = products.map(
          (handle, index) =>
            `q_${index}: product(handle: "${handle}"){ ...PLPProductDetail }`
        );

        return this.$getShopifyData({
          query: gql`query { ${queries.join("")} } ${PRODUCT_DETAIL}`,
        }).then(({ data }) => Object.values(data));
      };

      return compose(
        otherwise(() => []),
        andThen(unnest),
        xs => Promise.all(xs),
        map(getProducts),
        splitEvery(12)
      )(productIds);
    },

    getShopifyProduct(item) {
      return this.normalizeShopifyProducts[item.handle];
    },
    getOptions({ options = [] } = {}) {
      return options.length ? options : [];
    },
    getProduct(item) {
      const shopifyProduct = this.getShopifyProduct(item);

      const { tags, named_tags: namedTags } = item;

      // algolia puts tags with : in named tags e.g. "mv:my-new-design-headboard"
      const campaignTags = namedTags.campaign
        ? Array.isArray(namedTags.campaign)
          ? namedTags.campaign.map(namedTag => `campaign:${namedTag}`)
          : [`campaign:${namedTags?.campaign}`]
        : [];
      const mvTag = namedTags.mv ? `mv:${namedTags.mv}` : null;
      const updatedTags = [...tags, ...campaignTags, mvTag].filter(Boolean);

      return {
        id: item.id,
        title: item.title,
        handle: item.handle,
        collections: item.collections,
        tags: updatedTags,
        metafields: {
          ...item.meta?.snooze,
          reviews_average: item.meta?.yotpo?.reviews_average,
          reviews_count: item.meta?.yotpo?.reviews_count,
        },
        options: this.getOptions(shopifyProduct),
        variants: this.getVariants(shopifyProduct),
        variantsCount: item?.variants_count,
      };
    },
    getSelectedVariant(item) {
      return {
        sku: item.sku,
        price: item.price,
        id: item.objectID,
        compareAtPrice: item.compare_at_price,
        variantTitle: item.variant_title,
        selectedOptions: item.options,
      };
    },
    getVariants(shopifyProduct) {
      return shopifyProduct?.variants?.nodes?.map(getVariant) ?? [];
    },
    refineNext() {
      this.loadMoreClicked = true;
      this.state.showMore();
    },
    refinePrevious() {
      this.state.showPrevious();
    },
    scrollToBack(element, targetPaths) {
      this.$nextTick(() => {
        const firstElement = path(targetPaths, element);
        if (firstElement == null) {
          return;
        }

        const { top } = firstElement?.getBoundingClientRect?.() ?? { top: 0 };
        const offset = document.documentElement.scrollTop;
        this.scrollToTop(
          top + offset - this.topBarHeight - this.topBannerHeight
        );
        this.loadMoreClicked = false;
      });
    },
    scrollToTop(elementTop) {
      if (!window) {
        return;
      }

      window.scrollTo({
        top: elementTop,
        left: 0,
        behavior: "smooth",
      });
    },
    watchQuery(newQuery, oldQuery) {
      const onlyQueryChanged =
        equals(["query", "sortBy"], Object.keys(newQuery)) &&
        newQuery.query !== oldQuery.query;

      const onFirstPage = isNil(newQuery.page);

      if (onlyQueryChanged) {
        this.$nextTick(() => {
          this.scrollToTop(0);
        });
      } else if (onFirstPage && !isEmpty(oldQuery)) {
        this.scrollToBack(this.$refs.productContainer, ["firstChild"]);
      }
    },
    getGtmData(item, index) {
      return {
        title: item.title,
        variant_title: item.variant_title,
        sku: item.sku,
        vendor: item.vendor,
        compare_at_price: item.compare_at_price,
        price: item.price,
        inventory_quantity: item.inventory_quantity,
        product_type: item.product_type,
        position: index,
      };
    },
    visibilityChanged(isVisible, entry, item, index) {
      if (isVisible && this.shopifyProductsLoaded) {
        let gtmData = this.getGtmData(item, index);
        const list = this.generateListString();
        this.gtmProductImpressionEvent({ ...gtmData, list });
      }
    },
    getOptimizelyFilters() {
      const facetsMap = this.shownFacets.reduce(
        (acc, { name, title }) => ({ ...acc, [name]: title }),
        {}
      );
      const currentIndex = this.$store.state.siteConfig.algoliaProductsIndex;
      const renderState =
        this.state.instantSearchInstance.renderState[currentIndex];
      const priceRange = renderState?.range?.price?.range;
      const currentRefinements = renderState?.currentRefinements?.items ?? [];

      return currentRefinements.map(item => {
        return {
          filter: facetsMap?.[item.attribute] ?? item.attribute,
          operator: item.attribute === "price" ? "BTW" : "IN",
          values:
            item.attribute === "price"
              ? item.refinements.reduce(
                  (acc, { value, operator }) =>
                    operator === ">=" ? [value, acc[1]] : [acc[0], value],
                  [priceRange.min, priceRange.max]
                )
              : item.refinements.map(({ value, operator }) => value),
        };
      });
    },
    triggerGtmEvent(items) {
      this.$gtm.gtagEvent("view_item_list", {
        item_list_id: this.uid,
        item_list_name: this.uid,
        items: items.map((item, index) => ({
          item_id: item.sku,
          shopify_product_id: item.id,
          item_name: item.title,
          currency: "AUD",
          discount:
            item?.compare_at_price && item?.price
              ? item?.compare_at_price - item?.price
              : 0,
          index: index + 1,
          item_brand: item?.meta?.snooze?.brand_list ?? "",
          item_category: item?.meta?.snooze?.product_category ?? "",
          item_list_id: this.uid,
          item_list_name: this.uid,
          item_variant: item?.variant_title,
          price: item?.price,
          quantity: 1,
        })),
      });
    },
  },
};
