
import SwiperClass, { Navigation } from "swiper";

export default {
  props: {
    primary: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    label: { type: String, default: "" },
  },

  data: () => ({ showNav: false, swiper: null }),

  computed: {
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        loop: false,
        nested: true,
        loopFillGroupWithBlank: false,
        modules: [Navigation],
        navigation: {
          nextEl: ".alternate-grid-image-swiper-next",
          prevEl: ".alternate-grid-image-swiper-prev",
        },
      };
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      ".swiper.alternate-grid-image",
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },
};
