
import AppShopButton from "~/components/common/AppShopButton";

export default {
  name: "TextOverImage",

  components: { AppShopButton },

  props: {
    slice: { type: Object, required: true },
    context: { type: Object, required: true },
  },
};
