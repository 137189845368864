
import MediaWithBgColorItem from "./MediaWithBgColorItem";

export default {
  components: { MediaWithBgColorItem },

  props: {
    primary: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    title: { type: String, default: "" },
  },
};
