
import { path } from "ramda";

export default {
  name: "ImgItem",

  props: { slice: { type: Object, required: true } },

  computed: {
    imgMobile() {
      return path(["image", "mobile"], this.slice.primary);
    },
    imgMobileProps() {
      const imgDimensions = path(["dimensions"], this.imgMobile);

      return {
        src: path(["url"], this.imgMobile),
        alt: path(["alt"], this.imgMobile),
        ...this.getImageDimensions(imgDimensions, 400),
      };
    },
  },

  methods: {
    getImgDesktopProps(width = 1280) {
      const img = path(["image"], this.slice.primary);
      const imgDimensions = path(["dimensions"], img);

      return {
        src: path(["url"], img),
        alt: path(["alt"], img),
        ...this.getImageDimensions(imgDimensions, width),
      };
    },
  },
};
