
import CenterOffer from "../OffersSectionV2/CenterOffer.vue";

export default {
  name: "MemberEventOffer",

  components: { CenterOffer },

  props: { slice: { type: Object, required: true } },

  computed: {
    bgColor() {
      return this.slice.primary?.offer_banner_color;
    },
  },
};
