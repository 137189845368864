
import SwiperClass, { Navigation } from "swiper";
import CardMediaItem from "./CardMediaItem";

export default {
  components: { CardMediaItem },

  props: {
    primary: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    label: { type: String, default: "" },
  },

  data: () => ({ showNav: true, swiper: null }),

  computed: {
    swiperOptions() {
      return {
        slidesPerView: "auto",
        spaceBetween: 10,
        breakpoints: {
          1200: {
            slidesPerView: 3,
          },
        },
        loop: false,
        loopFillGroupWithBlank: false,
        modules: [Navigation],
        navigation: {
          nextEl: ".card-media-image-swiper-next",
          prevEl: ".card-media-image-swiper-prev",
        },
      };
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      ".swiper.card-media-image",
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },
};
