
import MediaWithBgImageItem from "./MediaWithBgImageItem";

export default {
  components: { MediaWithBgImageItem },

  props: {
    primary: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    slice: { type: Object, required: true },
  },

  computed: {
    image() {
      if (
        this.$device.isMobile &&
        this.slice?.primary?.background_image?.mobile
      ) {
        return this.slice?.primary?.background_image?.mobile;
      }

      return this.slice?.primary?.background_image?.desktop;
    },
    bgStyles() {
      const imgWidth = this.image?.dimensions?.width;
      const imgHeight = this.image?.dimensions?.height;
      const imgUrl = this.$img(this.image?.url, { width: imgWidth });

      return {
        backgroundImage: `url('${imgUrl}')`,
        minHeight: `${imgHeight}px`,
        backgroundPosition: "center top",
      };
    },
    titleimage() {
      const bannerImg = this.slice.primary?.title_image;
      const img = this.$device.isMobile
        ? bannerImg?.mobile
        : this.$device.isTablet
        ? bannerImg?.tablet
        : bannerImg?.desktop;

      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
  },
};
