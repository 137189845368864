
import DesktopReviews from "./DesktopReviews";
import MobileReviews from "./MobileReviews";

export default {
  name: "CustomerReview",

  components: { DesktopReviews, MobileReviews },

  props: { slice: { type: Object, required: true } },

  data: () => ({ siteReview: [] }),

  async fetch() {
    try {
      const { data } = await this.$axios.get(this.$config.yotpoUrl);

      this.siteReview = data.response?.reviews?.slice?.(0, 4) ?? [];
    } catch (err) {
      this.$bugsnag.notify(err);
    }
  },

  fetchOnServer: false,
};
