
import ImageGrid from "./ImageGrid";
import ImageWithSwiper from "./ImageWithSwiper";
import VideoGrid from "./VideoGrid";
import VideoWithSwiper from "./VideoWithSwiper";
import InternalVideoGrid from "./InternalVideoGrid";
import calculateFlexBasis from "~/mixins/calculateFlexBasisMixin";

export default {
  name: "MediaAlternateGrid",

  components: {
    ImageWithSwiper,
    ImageGrid,
    VideoWithSwiper,
    VideoGrid,
    InternalVideoGrid,
  },

  mixins: [calculateFlexBasis],

  props: { slice: { type: Object, required: true } },

  computed: {
    items() {
      return this.slice.fields.filter(
        field => field.image ?? field.video ?? field.internal_video
      );
    },
    hasOnlyImages() {
      return this.items.every(item => item.image);
    },
    hasOnlyVideos() {
      return this.items.every(item => item.video ?? item.internal_video);
    },
    hasOnlyInternalVideos() {
      return this.items.every(item => item.internal_video);
    },
    withSwiper() {
      return this.slice.label == "with-swipper";
    },
  },
};
